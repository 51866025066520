import logo from "./logo.svg";
import { BrowserRouter, Routes, Route, NavLink, Link } from 'react-router-dom';
import React, { useState, useEffect } from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import './Dashboard.css'

import Header from "./Header";
import Moulding from "./Pages/Moulding";
import WoodShop from "./Pages/WoodShop";
import Upholstery from './Pages/Upholstery';
import "./OpenOrders.css";
import ShippingData from "./ShippingData";
import OpenOrders from "./OpenOrders";
import Moulding_entry from "./Pages/Moulding_entry";

function AddData() {
  const [Area, setArea] = useState("");
  const [Mouldings, setMoulding] = useState(false);
  const [Woodshops, setWoodshop] = useState(false);
  const [Upholsterys, setUpholstery] = useState(false);
  const [shipped, setshipped] = useState(false);
  const [welcome, setwelcome] = useState(true);


  const [Value, setValue] = useState("PO Data Entry");

  const area = (e) => {
    //console.log("area called");

    if (e.target.value == "Moulding") {
      setMoulding(true);
      setWoodshop(false);
      setUpholstery(false);
      setshipped(false);
      setwelcome(false);
      setValue("PO Data Entry-Moulding");
    }

    if (e.target.value == "Woodshop") {
      setMoulding(false);
      setWoodshop(true);
      setUpholstery(false);
      setshipped(false);
      setwelcome(false);
      setValue("PO Data Entry-Woodshop");
    }

    if (e.target.value == "Upholstery") {
      setMoulding(false);
      setWoodshop(false);
      setUpholstery(true);
      setwelcome(false);
      setwelcome(false);
      setshipped(false);
      setValue("PO Data Entry-Upholstery");
    }
    if (e.target.value == "Shipping") {
      setMoulding(false);
      setWoodshop(false);
      setUpholstery(false);
      setshipped(true);
      setwelcome(false);
      setValue("PO Data Entry-Shipping Data");
    }
  };


  return (
    <div>
      <br></br>
      <Link to="/">
        <input type="button" class="btn btn-success" defaultValue="Logout" id="logout" />
      </Link>
      <div className="App">

        <div>
          <div className="content">

            <center>

              <h1>{Value}</h1>
              <div class="Container">
                <div class="row">
                  <div class="column" id="moldng">
                    <input
                      type="button"
                      class="button"
                      defaultValue="Moulding"
                      placeholder="Moulding PO"
                      id="btn"
                      name="Section"
                      onClick={area}
                    />
                    <span class="tooltiptext">Click here to Enter Moulding PO, Woodshop PO, Buy & Sell </span>
                  </div>
                  <div class="column" id="moldng">
                    <input
                      type="button"
                      class="button"
                      defaultValue="Upholstery"
                      name="Section"
                      id="btn"
                      onClick={area}
                    />
                    <span class="tooltiptext">Click here to Enter Upholstery PO </span>
                  </div>
                  <div class="column" id="moldng">
                    <input
                      type="button"
                      class="button"
                      defaultValue="Shipping"
                      name="Section"
                      id="btn"
                      onClick={area}
                    />
                    <span class="tooltiptext">Click here to Enter Shipping Data </span>

                  </div>
                </div>
              </div>
            </center>
          </div>
          {
            Mouldings ? <Moulding /> : <div></div>
          }
          {
            Upholsterys ? <Upholstery /> : <div></div>
          }
          {
            shipped ? <ShippingData /> : <div></div>
          }
        </div>


      </div>
      {welcome ?
        <section>
          <div class="welcome">
            <h2>Realform</h2>
            <h2>Realform</h2>
          </div>
        </section>
        : null
      }

    </div >
  );
}


export default AddData

import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link, Redirect, useParams } from "react-router-dom";
import "./MainPage.css";
import { Host } from '../App';


function Upholstery() {
  const [Area, setArea] = useState("");
  var [Date1, setDate1] = useState(new Date());

  const [part_dataset, setpart_dataset] = useState([]);
  const [Upholstery_, setUpholstery_] = useState(true);
  const [Cushions, setCushions] = useState(false);
  const [Buds, setBuds] = useState(false);
  const [Pleat, setPleat] = useState(false);
  const [Drape, setDrape] = useState(false);
  const [Haru, setHaru] = useState(false);
  const [uphide, setuphide] = useState("");
  const [cushide, setcushide] = useState("none");
  const [budshide, setbudshide] = useState("none");

  var [Po_No, setPo_No] = useState("");
  const [Part_No, setPart_No] = useState({ Part_No: {} });
  var [Po_Ship_Date, setPo_Ship_Date] = useState(Date1.toISOString().slice(0, 10));
  const [Part_Description, setPart_Description] = useState({ Part_Description: [] });
  const [Customer, setCustomer] = useState({ Customer: [] });
  var [Qty, setQty] = useState("");
  const [Po_Date, setPo_Date] = useState(Date1.toISOString().slice(0, 10)); useState("");
  const [Fabric_Status, setFabric_Status] = useState("");
  const [Fabric_Style, setFabric_Style] = useState("");
  const [Fabric_Color, setFabric_Color] = useState("");
  const [Orientation, setOrientation] = useState("");
  const [Base_Color, setBase_Color] = useState("");
  const [Fabric_Outside_Back, setFabric_Outside_Back] = useState("");
  const [Fabric_Inside_Back, setFabric_Inside_Back] = useState("");
  const [Fabric_Seat, setFabric_Seat] = useState("");
  const [Piping, setPiping] = useState("");
  const [Fabric_Back, setFabric_Back] = useState("");
  const [Wood_Outside_Back, setWood_Outside_Back] = useState("");
  const [Moulding, setMoulding] = useState("");
  const [Woodshop, setWoodshop] = useState("");
  const [Upholstery, setUpholstery] = useState("");
  const [Unit_Price, setUnit_Price] = useState("");
  const [Comments, setComments] = useState("");
  var [SalesOrderNo, setSalesOrderNo] = useState("");



  var [Po_No1, setPo_No1] = useState("");
  const [Part_No1, setPart_No1] = useState({ Part_No: {} });
  var [Po_Ship_Date1, setPo_Ship_Date1] = useState(Date1.toISOString().slice(0, 10));
  const [Part_Description1, setPart_Description1] = useState({ Part_Description: [] });
  const [Customer1, setCustomer1] = useState({ Customer: [] });
  var [Qty1, setQty1] = useState("");
  const [Po_Date1, setPo_Date1] = useState(Po_Date);
  const [Fabric_Status1, setFabric_Status1] = useState("");
  const [Fabric_Style1, setFabric_Style1] = useState("");
  const [Fabric_Color1, setFabric_Color1] = useState("");
  const [Orientation1, setOrientation1] = useState("");
  const [Base_Color1, setBase_Color1] = useState("");
  const [Fabric_Outside_Back1, setFabric_Outside_Back1] = useState("");
  const [Fabric_Inside_Back1, setFabric_Inside_Back1] = useState("");
  const [Fabric_Seat1, setFabric_Seat1] = useState("");
  const [Piping1, setPiping1] = useState("");
  const [Fabric_Back1, setFabric_Back1] = useState("");
  const [Wood_Outside_Back1, setWood_Outside_Back1] = useState("");
  const [Moulding1, setMoulding1] = useState("");
  const [Woodshop1, setWoodshop1] = useState("");
  const [Upholstery1, setUpholstery1] = useState("");
  const [Unit_Price1, setUnit_Price1] = useState("");
  const [Comments1, setComments1] = useState("");
  var [SalesOrderNo1, setSalesOrderNo1] = useState("");


  var [Po_No2, setPo_No2] = useState("");
  const [Part_No2, setPart_No2] = useState({ Part_No: {} });
  var [Po_Ship_Date2, setPo_Ship_Date2] = useState(Date1.toISOString().slice(0, 10));
  const [Part_Description2, setPart_Description2] = useState({ Part_Description: [] });
  const [Customer2, setCustomer2] = useState({ Customer: [] });
  var [Qty2, setQty2] = useState("");
  const [Po_Date2, setPo_Date2] = useState(Po_Date);
  const [Fabric_Status2, setFabric_Status2] = useState("");
  const [Fabric_Style2, setFabric_Style2] = useState("");
  const [Fabric_Color2, setFabric_Color2] = useState("");
  const [Orientation2, setOrientation2] = useState("");
  const [Base_Color2, setBase_Color2] = useState("");
  const [Fabric_Outside_Back2, setFabric_Outside_Back2] = useState("");
  const [Fabric_Inside_Back2, setFabric_Inside_Back2] = useState("");
  const [Fabric_Seat2, setFabric_Seat2] = useState("");
  const [Piping2, setPiping2] = useState("");
  const [Fabric_Back2, setFabric_Back2] = useState("");
  const [Wood_Outside_Back2, setWood_Outside_Back2] = useState("");
  const [Moulding2, setMoulding2] = useState("");
  const [Woodshop2, setWoodshop2] = useState("");
  const [Upholstery2, setUpholstery2] = useState("");
  const [Unit_Price2, setUnit_Price2] = useState("");
  const [Comments2, setComments2] = useState("");
  var [SalesOrderNo2, setSalesOrderNo2] = useState("");

  var token = (window.localStorage.getItem("acessToken"));

  // to retreive all data
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)
    });
  }, [])

  const area = () => {
    if (Area == "Upholstery") {
      setUpholstery_(true);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setPleat(false);
    }
    if (Area == "Cushions") {
      setUpholstery_(false);
      setCushions(true);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setPleat(false);
    }
    if (Area == "Buds") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(true);
      setDrape(false);
      setHaru(false);
      setPleat(false);
    }
    if (Area == "Drape") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(true);
      setHaru(false);
      setPleat(false);

    }

    if (Area == "Haru") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(true);
      setPleat(false);
    }

    if (Area == "Pleat") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setPleat(true);

    }
  };
  const submitValue = (e) => {
    setPart_No({ Part_No: e.target.value });
    setCustomer({ Customer: (part_dataset.find((x) => x.Part_No === e.target.value).Customer) });
    setPart_Description({ Part_Description: part_dataset.find((x) => x.Part_No === e.target.value).Part_Description });
    setMoulding({ Moulding: part_dataset.find((x) => x.Part_No === e.target.value).MOLDING });
    setWoodshop({ Woodshop: part_dataset.find((x) => x.Part_No === e.target.value).WOODSHOP });
    setUpholstery({ Upholstery: part_dataset.find((x) => x.Part_No === e.target.value).UPHOLSTERY });
  }
  const submitValue1 = (e) => {
    setPart_No1({ Part_No: e.target.value });
    setCustomer1({ Customer: (part_dataset.find((x) => x.Part_No === e.target.value).Customer) });
    setPart_Description1({ Part_Description: part_dataset.find((x) => x.Part_No === e.target.value).Part_Description });
    setMoulding1({ Moulding: part_dataset.find((x) => x.Part_No === e.target.value).MOLDING });
    setWoodshop1({ Woodshop: part_dataset.find((x) => x.Part_No === e.target.value).WOODSHOP });
    setUpholstery1({ Upholstery: part_dataset.find((x) => x.Part_No === e.target.value).UPHOLSTERY });
  }
  const submitValue2 = (e) => {
    setPart_No2({ Part_No: e.target.value });
    setCustomer2({ Customer: (part_dataset.find((x) => x.Part_No === e.target.value).Customer) });
    setPart_Description2({ Part_Description: part_dataset.find((x) => x.Part_No === e.target.value).Part_Description });
    setMoulding2({ Moulding: part_dataset.find((x) => x.Part_No === e.target.value).MOLDING });
    setWoodshop2({ Woodshop: part_dataset.find((x) => x.Part_No === e.target.value).WOODSHOP });
    setUpholstery2({ Upholstery: part_dataset.find((x) => x.Part_No === e.target.value).UPHOLSTERY });
  }
  const submitPost = () => {

    const data1 = [Po_No1, Part_No1, Po_Ship_Date1, JSON.parse(JSON.stringify(Part_Description1.Part_Description)), JSON.parse(JSON.stringify(Customer1.Customer)), Qty1, Po_Date1, Fabric_Status1, Fabric_Style1, Fabric_Color1, Orientation1, Base_Color1, Fabric_Inside_Back1, Fabric_Outside_Back1, Fabric_Seat1, Piping1, Fabric_Back1, Wood_Outside_Back1, (Moulding1.Moulding), (Woodshop1.Woodshop), (Upholstery1.Upholstery), Unit_Price1, Comments1, SalesOrderNo1]
    const data2 = [Po_No2, Part_No2, Po_Ship_Date2, JSON.parse(JSON.stringify(Part_Description2.Part_Description)), JSON.parse(JSON.stringify(Customer2.Customer)), Qty2, Po_Date2, Fabric_Status2, Fabric_Style2, Fabric_Color2, Orientation2, Base_Color2, Fabric_Inside_Back2, Fabric_Outside_Back2, Fabric_Seat2, Piping2, Fabric_Back2, Wood_Outside_Back2, (Moulding2.Moulding), (Woodshop2.Woodshop), (Upholstery2.Upholstery), Unit_Price2, Comments2, SalesOrderNo2]

    Axios.post(`https://` + Host + `/api/create/Upholstery`, { Po_No: Po_No, Part_No: Part_No, Po_Ship_Date: Po_Ship_Date, Part_Description: JSON.parse(JSON.stringify(Part_Description.Part_Description)), Customer: JSON.parse(JSON.stringify(Customer.Customer)), Qty: Qty, Po_Date: Po_Date, Fabric_Status: Fabric_Status, Fabric_Style: Fabric_Style, Fabric_Color: Fabric_Color, Orientation: Orientation, Base_Color: Base_Color, Fabric_Inside_Back: Fabric_Inside_Back, Fabric_Outside_Back: Fabric_Outside_Back, Fabric_Seat: Fabric_Seat, Piping: Piping, Fabric_Back: Fabric_Back, Wood_Outside_Back: Wood_Outside_Back, Moulding: (Moulding.Moulding), Woodshop: (Woodshop.Woodshop), Upholstery: (Upholstery.Upholstery), Unit_Price: Unit_Price, Comments: Comments, SalesOrderNo: SalesOrderNo, data1, data2, token })

    setTimeout(function () { window.location.reload(); }, 2000);
  };
  return (
    <div className="Upholstery_data">
      <div class="Container container-fluid">
        <div class="row">
          <div class="col">
            <select
              type="select"
              class="form-control form-rounded"
              id="Area"
              placeholder="Area"
              name="area"
              onKeyDown={area}
              onTouchMove={area}
              onSelect={area}
              onChange={(e) => {
                setArea(e.target.value);
              }}
              onClick={area}
            >
              <option value="Upholstery">Upholstery</option>
              <option value="Cushions">Cushions/Panels</option>
              <option value="Buds">Buds</option>
              <option value="Pleat">Pleat/Melvin</option>
              <option value="Drape">Drape</option>
              <option value="Haru">Haru</option>
            </select>
          </div>
          <div class="col"></div>
          <div class="col"></div>
          <div class="col"></div>
        </div>
      </div>
      <br></br>
      <br></br>
      <div>
        <table class="table table-bordered">
          <thead>
            <tr>
              <th>PO NO</th>
              <th>PART NO</th>
              <th>PO SHIP DATE</th>
              <th>PART DESCRIPTION</th>
              <th>CUSTOMER</th>
              <th>QTY</th>
              <th>PO DATE</th>
              {Buds || Cushions || Upholstery_ ? <th>FABRIC STATUS</th> : null}
              {Cushions || Buds || Haru ? <th> FABRIC STYLE / FABRIC BODY</th> : null}
              {Cushions || Buds ? <th>FABRIC COLOR / FABRIC TOP</th> : null}
              {Cushions ? <th>ORIENTATION</th> : null}
              {Cushions ? <th>SALES ORDER NO</th> : null}
              {Pleat || Drape ? <th>BASE COLOR</th> : null}
              {Pleat || Drape ? <th>FABRIC INSIDE BACK</th> : null}
              {Pleat || Drape ? <th>FABRIC OUTSIDE BACK</th> : null}
              {Pleat || Drape ? <th>FABRIC SEAT</th> : null}
              {Pleat ? <th>PIPING</th> : null}
              {Drape ? <th>FABRIC BACK</th> : null}
              {Haru ? <th>WOOD OUTSIDE BACK</th> : null}
              <th>UNIT PRICE</th>
              <th>COMMENTS</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <input type="text" class="form-control form-rounded" name="PoNo" onBlur={(e) => { setPo_No(e.target.value) }} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="PartNo" onBlur={(e) => { setPart_No(e.target.value) }} onChange={submitValue.bind(this)} list="InternalPartNo" />
                <div class="scrollbar">
                  <datalist id="InternalPartNo">
                    <option></option>
                    {part_dataset.map(x => { return <option>{x.Part_No}</option> })}
                  </datalist>
                </div>
              </td>
              <td>
                <input type="date" class="form-control form-rounded" name="PoShipDate" defaultValue={Date1.toISOString().slice(0, 10)} onBlur={(e) => { setPo_Ship_Date(e.target.value) }} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="PartDescription" value={Part_Description.Part_Description} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Customer" value={Customer.Customer} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Qty" onBlur={(e) => { setQty(e.target.value) }} />
              </td>
              <td>
                <input type="date" class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} name="PoDate" onBlur={(e) => {
                  setPo_Date(e.target.value)
                  setPo_Date1(e.target.value)
                  setPo_Date2(e.target.value)
                }} />
              </td>
              {Buds || Cushions || Upholstery_ ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricStatus" onChange={(e) => { setFabric_Status(e.target.value) }} list="FabricStatus" />
                  <div class="scrollbar">
                    <datalist id="FabricStatus">
                      <option></option>
                      <option>COM</option>
                      <option>PUR</option>
                      <option>IN HOUSE</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Cushions || Buds || Haru ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricStyle" onBlur={(e) => { setFabric_Style(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Cushions || Buds ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricColor" onBlur={(e) => { setFabric_Color(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Cushions ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="Orientation" onChange={(e) => { setOrientation(e.target.value) }} list="orientation" />
                  <div class="scrollbar">
                    <datalist id="orientation">
                      <option></option>
                      <option>RR</option>
                      <option>OTB</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Cushions ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="SalesOrderNo" onBlur={(e) => { setSalesOrderNo(e.target.value) }} />
                </td>) : (null)}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="BaseColor" onBlur={(e) => { setBase_Color(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricInsideBack" onBlur={(e) => { setFabric_Inside_Back(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricOutsideBack" onBlur={(e) => { setFabric_Outside_Back(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricSeat" onBlur={(e) => { setFabric_Seat(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat ? (
                <td>
                  <input type="boolean" class="form-control form-rounded" name="Piping" onChange={(e) => { setPiping(e.target.value) }} list="PIPING" />
                  <div class="scrollbar">
                    <datalist id="PIPING">
                      <option></option>
                      <option>YES</option>
                      <option>NO</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricBack" onBlur={(e) => { setFabric_Back(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Haru ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="WoodOutsideBack" onBlur={(e) => { setWood_Outside_Back(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              <td>
                <input type="text" class="form-control form-rounded" name="UnitPrice" onBlur={(e) => { setUnit_Price(e.target.value) }} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Comments" onBlur={(e) => { setComments(e.target.value) }} />
              </td>
            </tr>
            <tr>
              <td>
                <input type="text" class="form-control form-rounded" name="PoNo" onBlur={(e) => { setPo_No1(e.target.value) }} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="PartNo" onBlur={(e) => { setPart_No1(e.target.value) }} onChange={submitValue1.bind(this)} list="InternalPartNo" />
                <div class="scrollbar">
                  <datalist id="InternalPartNo">
                    <option></option>
                    {part_dataset.map(x => { return <option>{x.Part_No}</option> })}
                  </datalist>
                </div>
              </td>
              <td>
                <input type="date" class="form-control form-rounded" name="PoShipDate" defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => { setPo_Ship_Date1(e.target.value) }} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="PartDescription" value={Part_Description1.Part_Description} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Customer" value={Customer1.Customer} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Qty" onChange={(e) => { setQty1(e.target.value) }} />
              </td>
              <td>
                <input type="date" class="form-control form-rounded" Value={Po_Date} name="PoDate" onChange={(e) => { setPo_Date1(e.target.value) }} />
              </td>


              {Buds || Cushions || Upholstery_ ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricStatus" onChange={(e) => { setFabric_Status1(e.target.value) }} list="FabricStatus" />
                  <div class="scrollbar">
                    <datalist id="FabricStatus">
                      <option></option>
                      <option>COM</option>
                      <option>PUR</option>
                      <option>IN HOUSE</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Cushions || Buds || Haru ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricStyle" onChange={(e) => { setFabric_Style1(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Cushions || Buds ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricColor" onChange={(e) => { setFabric_Color1(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Cushions ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="Orientation" onChange={(e) => { setOrientation1(e.target.value) }} list="orientation" />
                  <div class="scrollbar">
                    <datalist id="orientation">
                      <option></option>
                      <option>RR</option>
                      <option>OTB</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Cushions ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="SalesOrderNo" onBlur={(e) => { setSalesOrderNo1(e.target.value) }} />
                </td>) : (null)}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="BaseColor" onChange={(e) => { setBase_Color1(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricInsideBack" onChange={(e) => { setFabric_Inside_Back1(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricOutsideBack" onChange={(e) => { setFabric_Outside_Back1(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricSeat" onChange={(e) => { setFabric_Seat1(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat ? (
                <td>
                  <input type="boolean" class="form-control form-rounded" name="Piping" onChange={(e) => { setPiping1(e.target.value) }} list="PIPING" />
                  <div class="scrollbar">
                    <datalist id="PIPING">
                      <option></option>
                      <option>YES</option>
                      <option>NO</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricBack" onChange={(e) => { setFabric_Back1(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Haru ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="WoodOutsideBack" onChange={(e) => { setWood_Outside_Back1(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              <td>
                <input type="text" class="form-control form-rounded" name="UnitPrice" onChange={(e) => { setUnit_Price1(e.target.value) }} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Comments" onChange={(e) => { setComments1(e.target.value) }} />
              </td>
            </tr>
            <tr>
              <td>
                <input type="text" class="form-control form-rounded" name="PoNo" onBlur={(e) => { setPo_No2(e.target.value) }} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="PartNo" onBlur={(e) => { setPart_No2(e.target.value) }} onChange={submitValue2.bind(this)} list="InternalPartNo" />
                <div class="scrollbar">
                  <datalist id="InternalPartNo">
                    <option></option>
                    {part_dataset.map(x => { return <option>{x.Part_No}</option> })}
                  </datalist>
                </div>
              </td>
              <td>
                <input type="date" class="form-control form-rounded" defaultValue={Date1.toISOString().slice(0, 10)} name="PoShipDate" onChange={(e) => { setPo_Ship_Date2(e.target.value) }} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="PartDescription" value={Part_Description2.Part_Description} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Customer" value={Customer2.Customer} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Qty" onChange={(e) => { setQty2(e.target.value) }} />
              </td>
              <td>
                <input type="date" class="form-control form-rounded" Value={Po_Date} name="PoDate" onChange={(e) => { setPo_Date2(e.target.value) }} />
              </td>
              {Buds || Cushions || Upholstery_ ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricStatus" onChange={(e) => { setFabric_Status2(e.target.value) }} list="FabricStatus" />
                  <div class="scrollbar">
                    <datalist id="FabricStatus">
                      <option></option>
                      <option>COM</option>
                      <option>PUR</option>
                      <option>IN HOUSE</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Cushions || Buds || Haru ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricStyle" onChange={(e) => { setFabric_Style2(e.target.value) }} />
                </td>
              ) : (null)}
              {Cushions || Buds ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricColor" onChange={(e) => { setFabric_Color2(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Cushions ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="Orientation" onChange={(e) => { setOrientation2(e.target.value) }} list="orientation" />
                  <div class="scrollbar">
                    <datalist id="orientation">
                      <option></option>
                      <option>RR</option>
                      <option>OTB</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Cushions ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="SalesOrderNo" onBlur={(e) => { setSalesOrderNo2(e.target.value) }} />
                </td>) : (null)}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="BaseColor" onChange={(e) => { setBase_Color2(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricInsideBack" onChange={(e) => { setFabric_Inside_Back2(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricOutsideBack" onChange={(e) => { setFabric_Outside_Back2(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat || Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricSeat" onChange={(e) => { setFabric_Seat2(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Pleat ? (
                <td>
                  <input type="boolean" class="form-control form-rounded" name="Piping" onChange={(e) => { setPiping2(e.target.value) }} list="PIPING" />
                  <div class="scrollbar">
                    <datalist id="PIPING">
                      <option></option>
                      <option>YES</option>
                      <option>NO</option>
                    </datalist>
                  </div>
                </td>
              ) : (
                null
              )}
              {Drape ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="FabricBack" onChange={(e) => { setFabric_Back2(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              {Haru ? (
                <td>
                  <input type="text" class="form-control form-rounded" name="WoodOutsideBack" onChange={(e) => { setWood_Outside_Back2(e.target.value) }} />
                </td>
              ) : (
                null
              )}
              <td>
                <input type="text" class="form-control form-rounded" name="UnitPrice" onChange={(e) => { setUnit_Price2(e.target.value) }} />
              </td>
              <td>
                <input type="text" class="form-control form-rounded" name="Comments" onChange={(e) => { setComments2(e.target.value) }} />
              </td>
            </tr>

          </tbody>
        </table>
        <input
          type="button"
          class="btn btn-success"
          defaultValue="Submit"
          name="Submit"
          id="submitbtn"
          onClick={submitPost}
        />
      </div>
    </div>
  );
}

export default Upholstery;

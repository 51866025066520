import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Link, Redirect, useParams } from "react-router-dom"
import Header from './Header';
import './OpenOrders.css'
import './Dashboard.css'
import { Host } from './App';
import real_logo from "./real_logo_green.png";
import real_l from "./real_logo.png";
function OpenOrders() {

  const [postList, setPostList] = useState([]);
  const [PoNo, setPoNo] = useState({});
  const [PartNo, setPartNo] = useState({});

  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const formattedDate = new Date(date).toLocaleDateString();
  const formattedTime = new Date(date).toLocaleTimeString();
  const [Date1, setDate1] = useState(new Date());
  const [Postartdate, setPoStartdate] = useState('2023-01-01');
  const [Poenddate, setPoEnddate] = useState(Date1.toISOString().slice(0, 10));
  const [customer, setCustomer] = useState({});
  const [partInternalNo, setPartInternalNo] = useState({});
  const [operators, setoperators] = useState([]);
  const [Customers, setCustomers] = useState([]);
  const [partNo, setpartNo] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  const [Mouldings, setMoulding] = useState(false);
  const [Woodshops, setWoodshop] = useState(false);
  const [Upholsterys, setUpholstery] = useState(false);
  const [shipped, setshipped] = useState(false);
  const [Value, setValue] = useState("Open Orders");
  const [Section, setSection] = useState("All");
  var [order, setOrder] = useState("1");
  const [Area, setArea] = useState("");
  var [Type, setType] = useState(" ");
  const [rank, setrank] = useState(false);
  var Ranked = (window.localStorage.getItem("Rank"));
  const [Upholstery_, setUpholstery_] = useState(true);
  const [Cushions, setCushions] = useState(false);
  const [Buds, setBuds] = useState(false);
  const [Pleat, setPleat] = useState(false);
  const [Drape, setDrape] = useState(false);
  const [Haru, setHaru] = useState(false);
  var token = (window.localStorage.getItem("acessToken"));
  var User = JSON.parse(window.localStorage.getItem("Name"));
  var totalQtyShipped = 0;

  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });
  const queryParams = {
    PoNo: PoNo,
    PartNo: PartNo,
    Postartdate: Postartdate,
    Poenddate: Poenddate,
    customer: customer,
    order: order,
    section: Section,
    token: token,
    Type: Type
  };
  // Default Data 
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/openorders/search`, { params: queryParams }).then((data) => {
      setPostList(data.data)
    })
  }, [PoNo, PartNo, Postartdate, Poenddate, customer, Section, order, Type])
  //get rank
  useEffect(() => {
    if (Ranked === '"Admin"') {
      setrank(true);
    }
  }, [Ranked]);
  // to retreive customers
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/Customers/${token}`).then((data) => {
      setCustomers(data.data)
    });
  }, [])
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/partNo/${token}`).then((data) => {
      setpartNo(data.data)
    });
  }, [])
  // to retreive part dataset
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)
    });
  }, [])
  // method to retrieve data combinely
  const search = () => {
    Axios.get(`https://` + Host + `/api/openorders/search/${JSON.stringify(PoNo)},${JSON.stringify(PartNo)},${Postartdate},${Poenddate},${JSON.stringify(customer)},${order},${Section},${token}`).then((data) => {
      setPostList(data.data)
    })
  }

  const area = (e) => {
    //console.log("area called");
    if (e.target.value == "Molding") {

      setMoulding(true);
      setWoodshop(false);
      setUpholstery(false);
      setshipped(false);
      setValue("Moulding Open Orders");
      setSection("Moulding");
      Axios.get(`https://` + Host + `/api/get/Molding/${order},${token}`).then((data) => {
        setPostList(data.data)
      });
      //console.log(postList);
    }
    if (e.target.value == "Woodshop") {
      setMoulding(false);
      setWoodshop(true);
      setUpholstery(false);
      setshipped(false);
      setValue("Woodshop Open Orders");
      setSection("Woodshop");
      Axios.get(`https://` + Host + `/api/get/Woodshop/${order},${token}`).then((data) => {
        setPostList(data.data)
      });
    }
    if (e.target.value == "Upholstery") {
      setMoulding(false);
      setWoodshop(false);
      setUpholstery(true);
      setshipped(false);
      setValue("Upholstery Open Orders");
      setSection("Upholstery");
      Axios.get(`https://` + Host + `/api/get/Upholstery/${order},${token}`).then((data) => {
        setPostList(data.data)
      });
    }
    if (e.target.value == "ShippedData") {
      setMoulding(false);
      setWoodshop(false);
      setUpholstery(false);
      setshipped(true);
      setValue("Shipping Data");
    }
  };
  const section = () => {
    if (Area == "Upholstery") {
      setUpholstery_(true);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setPleat(false);
      setType("");
    }
    if (Area == "Cushions") {
      setUpholstery_(false);
      setCushions(true);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setPleat(false);
      setType("Cushion");
    }
    if (Area == "Buds") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(true);
      setDrape(false);
      setHaru(false);
      setPleat(false);
      setType("Bud");
    }
    if (Area == "Drape") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(true);
      setHaru(false);
      setPleat(false);
      setType("Drape");

    }

    if (Area == "Haru") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(true);
      setPleat(false);
      setType("Haru");
    }

    if (Area == "Pleat") {
      setUpholstery_(false);
      setCushions(false);
      setBuds(false);
      setDrape(false);
      setHaru(false);
      setPleat(true);
      setType("Pleat");

    }
  };

  const Order = () => {
    //console.log(order);
    Axios.get(`https://` + Host + `/api/get/default/${order}`).then((data) => {
      setPostList(data.data)
    });
  }
  const handlePrint = () => {

    const table = document.querySelector('#alldata_openorder');
    const printWindow = window.open("", "", "height=600,width=800");
    const img = document.createElement('img');
    img.src = real_l;

    printWindow.document.write(`
      <html>
        <head>
          <title></title>
          <style>
          h1 {
            display: inline-block;
            vertical-align: middle;
            margin: 0;
            text-align: center; /* Center the heading */
            width: 100%; /* Occupy the full width */
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          img {
            display: inline-block;
            height: 50px; /* Set the height of the image as required */
            margin-right: 10px; /* Set the spacing between the image and title as required */
          }
          
          table {
            border: 1px solid black;
            border-collapse: collapse;
            width: 100%;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          
          th, td {
            border: 1px solid black;
            padding: 5px;
          }
          
          tr:nth-child(even) {
            background-color: #f2f2f2; /* Apply the table-striped style */
          }
          
          .bottom-right {
            position:relative;
            bottom: 10px;
            right: 10px;
            font-family: Arial, sans-serif; /* Set the font family */
          }
          </style>
        </head>
        <body>
  
          <h1>${img.outerHTML} </h1>
          <table class="table table-striped">${(table.innerHTML)}</table>
          <p class="bottom-right">Printed By: ${User}</p>
          <p class="bottom-right">Date Created: ${formattedDate + '  ' + formattedTime}</p>
        </body>
      </html>`
    );
    printWindow.document.querySelector('title').insertAdjacentHTML('afterbegin', `  
    `);
    printWindow.document.close();
    printWindow.focus();
  };

  return (
    <div>
      <div>
        <br></br>
        <Link to="/">
          <input type="button" class="btn btn-success" defaultValue="Logout" id="logout" />
        </Link>
      </div>

      <div className="MainPage_data">

        <div class="Container" id="openorder_box">
          <h1>{Value}</h1>
          <div className="responsive-form-group">
            <div class="row" id='openorder_filter'>
              <div class="col">
                <div class="row">
                  {/* Grab the particular Operator name */}
                  <input type='text' class="form-control form-rounded" placeholder='PO NO' list="operators" onChange={(e) => {
                    setPoNo(e.target.value)
                  }} />
                </div>
                {Upholsterys ?
                  <div class="row">
                    <select
                      type="select"
                      class="form-control form-rounded"
                      id="Area"
                      placeholder="Area"
                      name="area"
                      onKeyDown={section}
                      onTouchMove={section}
                      onSelect={section}
                      onChange={(e) => {
                        setArea(e.target.value);
                      }}
                      onClick={section}
                    >
                      <option value="Upholstery">Upholstery</option>
                      <option value="Cushions">Cushions/Panels</option>
                      <option value="Buds">Buds</option>
                      <option value="Pleat">Pleat</option>
                      <option value="Drape">Drape</option>
                      <option value="Haru">Haru</option>
                    </select>
                  </div>
                  : null}
              </div>
              <div class="col">
                <div class="row">
                  {/* Grab the particular Operator name */}
                  <input type='text' class="form-control form-rounded" placeholder='PART NO' list="PartNo" onChange={(e) => {
                    setPartNo(e.target.value)
                  }} />
                  <datalist id="PartNo">
                    <option></option>
                    {partNo.map(x => {
                      return <option>{x.Part_No}</option>
                    })}
                  </datalist>
                </div>
                <div class="row">
                  <Link to="/Upholstery_Schedule">
                    <button className="btn btn-primary">Upholstery Schedule</button>
                  </Link>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  <div class="col" id="moldng">
                    {/* Grab the particular dates */}
                    <input type='date' class="form-control form-rounded" placeholder='PO SHIP DATE' defaultValue={Postartdate} onChange={(e) => {
                      setPoStartdate(e.target.value)
                    }} />
                    {/*<button class="btn btn-success" onClick={dates}>Submit</button > */}
                    <span class="tooltiptext">Filter By PO SHIP DATE </span>

                  </div>
                  <div class="col">
                    <input type='date' class="form-control form-rounded" placeholder='Operator' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                      setPoEnddate(e.target.value)
                    }} />
                  </div>
                </div>
              </div>
              <div class="col">
                <div class="row">
                  {/* Grab the particular Customer */}
                  <input type='text' class="form-control form-rounded" placeholder='Customer' list='Customers' onChange={(e) => {
                    setCustomer(e.target.value)
                  }} />
                  <datalist id="Customers">
                    <option></option>
                    {Customers.map(x => {
                      return <option>{x.Customer}</option>
                    })}
                  </datalist>
                </div>
                <div class="row">
                  <select
                    type="select"
                    class="form-control form-rounded"
                    id="order"
                    name="order"
                    onChange={(e) => {
                      setOrder(e.target.value);
                      order = e.target.value;

                    }}
                  >
                    <option value="1">Open Orders</option>
                    <option value="0"> Open/Closed Orders</option>
                  </select>
                </div>

              </div>
              <div class="col">
                <button className="btn btn-primary" onClick={handlePrint}>Print Table</button>

              </div>
            </div>
          </div>
          <div class="Container">
            <div class="row">
              <div class="column">
                <input
                  type="button"
                  class="button"
                  defaultValue="Molding"
                  id="btn"
                  name="Section"
                  onClick={area}
                />
              </div>
              <div class="column">
                <input
                  type="button"
                  class="button"
                  defaultValue="Woodshop"
                  name="Section"
                  id="btn"
                  onClick={area}
                />
              </div>
              <div class="column">
                <input
                  type="button"
                  class="button"
                  defaultValue="Upholstery"
                  name="Section"
                  id="btn"
                  onClick={area}
                />
              </div>
            </div>
          </div>
          <br></br>

          <div class="datatable-wide" id='alldata_openorder'><table class="table table-bordered table-condensed">
            <thead>
              <tr>
                <th>PO NO</th>
                <th>PART NO</th>
                <th>PO SHIP DATE</th>
                <th>PART DESCRIPTION</th>
                <th>CUSTOMER</th>
                <th>REMAINED QTY</th>
                {Upholsterys && Buds || Upholsterys && Cushions || Upholsterys && Upholstery_ ? <th>FABRIC_STATUS</th> : null}
                {Upholsterys && Cushions || Upholsterys && Buds || Upholsterys && Haru ? <th>FABRIC_STYLE / FABRIC BODY</th> : null}
                {Upholsterys && Cushions || Upholsterys && Buds ? <th>FABRIC_COLOR / FABRIC TOP</th> : null}
                {Upholsterys ? <th>ORIENTATION</th> : null}
                {Upholsterys && Pleat || Upholsterys && Drape ? <th>BASE_COLOR</th> : null}
                {Upholsterys && Pleat || Upholsterys && Drape ? <th>FABRIC_INSIDE_BACK</th> : null}
                {Upholsterys && Pleat || Upholsterys && Drape ? <th>FABRIC_OUTSIDE_BACK</th> : null}
                {Upholsterys && Pleat || Upholsterys && Drape ? <th>FABRIC_SEAT</th> : null}
                {Upholsterys && Pleat ? <th>PIPING</th> : null}
                {Upholsterys && Drape ? <th>FABRIC_BACK</th> : null}
                {Upholsterys && Haru ? <th>WOOD_OUTSIDE_BACK</th> : null}
                {Upholsterys ? <th>SALES_ORDER_NO</th> : null}
                <th id="comment">COMMENTS</th>
                {rank ? <th id="Unit_price">Unit Price</th> : null}
                {rank ? <th>Total</th> : null}
              </tr>
            </thead>

            {postList.map((val, key) => {
              totalQtyShipped = postList.reduce((acc, val) => acc + (val.Unit_price * val.Remained_parts), 0);
              return (

                <tbody>
                  <tr>
                    <td>{val.PO_NO}</td>
                    <td>{val.PART_NO}</td>
                    <td>{(val.PO_SHIP_DATE).slice(0, 10)}</td>
                    <td>{val.PART_DESCRIPTION}</td>
                    <td>{val.CUSTOMER}</td>
                    <td>{val.Remained_parts}</td>
                    {Upholsterys && Buds || Upholsterys && Cushions || Upholsterys && Upholstery_ ? <td> {val.FABRIC_STATUS}</td> : null}
                    {Upholsterys && Cushions || Upholsterys && Buds || Upholsterys && Haru ? <td>{val.FABRIC_STYLE}</td> : null}
                    {Upholsterys && Cushions || Upholsterys && Buds ? <td>{val.FABRIC_COLOR}</td> : null}
                    {Upholsterys ? <td>{val.ORIENTATION}</td> : null}
                    {Upholsterys && Pleat || Upholsterys && Drape ? <td>{val.BASE_COLOR}</td> : null}
                    {Upholsterys && Pleat || Upholsterys && Drape ? <td>{val.FABRIC_INSIDE_BACK}</td> : null}
                    {Upholsterys && Pleat || Upholsterys && Drape ? <td>{val.FABRIC_OUTSIDE_BACK}</td> : null}
                    {Upholsterys && Pleat || Upholsterys && Drape ? <td>{val.FABRIC_SEAT}</td> : null}
                    {Upholsterys && Pleat ? <td>{val.PIPING}</td> : null}
                    {Upholsterys && Drape ? <td>{val.FABRIC_BACK}</td> : null}
                    {Upholsterys && Haru ? <td>{val.WOOD_OUTSIDE_BACK}</td> : null}
                    {Upholsterys ? <td>{val.SALES_ORDER_NO}</td> : null}
                    <td id="comment">{val.COMMENTS}</td>
                    {rank ? <td id="Unit_price">{val.Unit_price}</td> : null}
                    {rank ? <td>{(val.Unit_price * val.Remained_parts).toFixed(2)}</td> : null}
                  </tr>
                </tbody>

              )
            })}
            {rank ? <th colSpan="6" style={{ "fontSize": "x-large" }}>Final Price</th> : null}
            {rank ? <th style={{ "fontSize": "x-large" }}>${totalQtyShipped.toFixed(2)}</th> : null}
            <th colSpan="2"></th>
          </table>
          </div>
        </div>

      </div>
    </div >
  )
}

export default OpenOrders
import React, { useState, useEffect } from 'react'

import Axios from 'axios';

function Trial() {
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFiles, setUploadedFiles] = useState([]); // State to store uploaded file URLs
  var [vaccation_data, setvaccation_data] = useState([]);

  // Function to handle file selection
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]); // Update state with selected file
  };
  useEffect(() => {
    console.log("called");
    Axios.get(`http://localhost:3002/get-urls/`).then((data) => {
      console.log(data);
      setvaccation_data(data.data);
    });
  }, []);
  // Function to handle file upload
  const uploadFile = () => {
    if (!selectedFile) {
      window.alert('Please select a file first');
      return;
    }

    const formData = new FormData();
    formData.append('file', selectedFile);

    Axios.post('http://localhost:3002/api/upload', formData)
      .then((response) => {
        if (response.status === 200) {
          window.alert(response.data.message);
          setUploadedFiles([...uploadedFiles, response.data.url]); // Add the new URL to the list
        }
      })
      .catch((error) => {
        console.error('Error uploading file:', error);
        window.alert('Error occurred while uploading the file');
      });
  };

  // Function to copy a file URL to the clipboard
  const copyToClipboard = (url) => {
    navigator.clipboard.writeText(url).then(() => {
      window.alert('URL copied to clipboard!');
    }).catch((err) => {
      console.error('Failed to copy URL:', err);
      window.alert('Failed to copy URL');
    });
  };

  return (
    <div>
      <h2>File Upload and Display with <code>"Node.js"</code></h2>
      <div>
        <input type="file" onChange={handleFileChange} />
        <button onClick={uploadFile}>Upload File</button>
      </div>

      {uploadedFiles.length > 0 && (
        <div>
          <h3>Uploaded Files:</h3>
          <ul>
            {uploadedFiles.map((url, index) => (
              <li key={index}>
                <a href={url} target="_blank" rel="noopener noreferrer">
                  {url}
                </a>
                <button onClick={() => copyToClipboard(url)}>Copy URL</button>
                {/* Check if the URL is an image and render it */}
                {url.match(/\.(jpeg|jpg|gif|png)$/) !== null && (
                  <img
                    src={url}
                    alt={`Uploaded file ${index + 1}`}
                    style={{ width: '200px', marginTop: '10px' }}
                  />
                )}
              </li>
            ))}
          </ul>

        </div>
      )}
      <table class="table table-bordered">
        <thead>
          <tr>
            <th>URL</th>
          </tr>
        </thead>
        {vaccation_data.map((val, key) => {
          return (
            <tbody>
              <tr key={key}>
                <a><td>{val.url}</td></a>
              </tr>
            </tbody>
          )
        })}
        <thead>
        </thead>
      </table>
    </div>
  );
}

export default Trial;

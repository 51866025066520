import React, { useState, useEffect } from 'react';
import { EditorState, RichUtils, convertToRaw, convertFromRaw } from 'draft-js';
import Editor from '@draft-js-plugins/editor';
import createInlineToolbarPlugin from '@draft-js-plugins/inline-toolbar';
import { ItalicButton, BoldButton, UnderlineButton } from '@draft-js-plugins/buttons';
import 'draft-js/dist/Draft.css';
import '@draft-js-plugins/inline-toolbar/lib/plugin.css';
import axios from 'axios';
import './Report.css';
import { Host } from '../../App'; // Adjust according to your project structure

// Initialize the inline toolbar plugin
const inlineToolbarPlugin = createInlineToolbarPlugin();
const { InlineToolbar } = inlineToolbarPlugin;

// Section headings
const headings = [
  'Health & Safety',
  'Employee Attendance',
  'Machine Update',
  'Assembly Update',
  'Rigid Molding',
  'Flex Molding',
  'Flex Trimming',
  'Shipping',
  'Equipment/ Maintenance Issues',
  'Task of the day',
  'Comments',
];

const New_ShiftReport = () => {
  const [editorStates, setEditorStates] = useState(() =>
    headings.map(() => EditorState.createEmpty())
  );
  const [files, setFiles] = useState([]);
  const [date, setDate] = useState(new Date().toISOString().slice(0, 10));
  const [shift, setShift] = useState('Morning');
  const supervisor = window.localStorage.getItem('Name')?.replace(/[{""}]/g, '') || '';

  useEffect(() => {
    const savedContent = localStorage.getItem('reportContent');
    if (savedContent) {
      try {
        const parsedContent = JSON.parse(savedContent);
        if (Array.isArray(parsedContent)) {
          const savedEditorStates = parsedContent.map((rawContent) =>
            EditorState.createWithContent(convertFromRaw(rawContent))
          );
          setEditorStates(savedEditorStates);
        } else {
          console.warn('Saved content is not an array:', parsedContent);
        }
      } catch (error) {
        console.error('Failed to parse saved content:', error);
      }
    }
  }, []);

  useEffect(() => {
    const content = editorStates.map((editorState) =>
      convertToRaw(editorState.getCurrentContent())
    );
    localStorage.setItem('reportContent', JSON.stringify(content));
  }, [editorStates]);

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
  };

  const handleSubmit = () => {
    const content = editorStates.map((editorState, index) => ({
      heading: headings[index],
      content: convertToRaw(editorState.getCurrentContent()),
    }));

    const formData = new FormData();
    formData.append('content', JSON.stringify(content));
    formData.append('date', date);
    formData.append('shift', shift);
    formData.append('supervisor', supervisor);
    files.forEach((file) => formData.append('files', file));

    axios
      .post('https://' + Host + '/save-report', formData)
      .then((response) => {
        window.localStorage.removeItem('reportContent');
        alert('Report saved successfully!');
        window.location.reload(false);
      })
      .catch((error) => {
        console.error('There was an error saving the report!', error);
        alert('Failed to save the report.');
      });
  };

  const handleEditorChange = (editorState, index) => {
    const newEditorStates = [...editorStates];
    newEditorStates[index] = editorState;
    setEditorStates(newEditorStates);
  };

  const handleKeyCommand = (command, index) => {
    const newState = RichUtils.handleKeyCommand(editorStates[index], command);
    if (newState) {
      handleEditorChange(newState, index);
      return 'handled';
    }
    return 'not-handled';
  };

  const toggleInlineStyle = (style, index) => {
    handleEditorChange(RichUtils.toggleInlineStyle(editorStates[index], style), index);
  };

  return (
    <div id="new-shift-report-block" style={{ padding: '20px', margin: 'auto' }}>
      <div id="new_shift_report_block">
        <div style={{ marginBottom: '20px' }}>
          <label style={{ marginLeft: '20px' }}>
            Date:
            <input
              type="date"
              value={date}
              onChange={(e) => setDate(e.target.value)}
              required
              className="form-control form-rounded"
              style={{ marginLeft: '10px', padding: '5px' }}
            />
          </label>
          <label style={{ marginLeft: '20px' }}>
            Shift:
            <select
              value={shift}
              className="form-control form-rounded"
              onChange={(e) => setShift(e.target.value)}
              style={{ marginLeft: '10px', padding: '5px' }}
            >
              <option value="Morning">Morning</option>
              <option value="Evening">Evening</option>
            </select>
          </label>
        </div>
        <div className="Shift_block" style={{ border: '1px solid gray', padding: '1%' }}>
          {headings.map((heading, index) => (
            <div key={index} style={{ marginBottom: '20px' }}>
              <p style={{ fontSize: '24px' }} id="new_shift-heading">{heading}</p>
              <div
                style={{
                  minHeight: '100px',
                  padding: '10px',
                  background: 'ghostwhite',
                  borderRadius: '4px',
                }}
                onClick={() => document.querySelectorAll('.DraftEditor-root')[index].focus()}
              >
                <Editor
                  editorState={editorStates[index]}
                  onChange={(editorState) => handleEditorChange(editorState, index)}
                  handleKeyCommand={(command) => handleKeyCommand(command, index)}
                  plugins={[inlineToolbarPlugin]}
                  className="form-control form-rounded"
                  id={`shift_section_editor_${index}`}
                />
                {/* Inline toolbar to provide text formatting options */}
                <InlineToolbar>
                  {(externalProps) => (
                    <>
                      <BoldButton {...externalProps} />
                      <ItalicButton {...externalProps} />
                      <UnderlineButton {...externalProps} />
                    </>
                  )}
                </InlineToolbar>
              </div>
            </div>
          ))}
        </div>
        <input
          type="file"
          className="form-control form-rounded"
          multiple
          onChange={handleFileChange}
          style={{ marginBottom: '20px' }}
        />
        <button
          onClick={handleSubmit}
          style={{
            padding: '10px 20px',
            backgroundColor: '#007bff',
            color: 'white',
            border: 'none',
            borderRadius: '5px',
            cursor: 'pointer',
          }}
        >
          Submit Report
        </button>
      </div>
    </div>
  );
};

export default New_ShiftReport;

import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { host } from '../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import "./MainPage.css";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { Button, Menu, Dropdown, Row } from 'antd';
import { Link } from "react-router-dom";
function Moulding_entry(props) {
  var token = (window.localStorage.getItem("acessToken"));
  const user = JSON.parse(window.localStorage.getItem("Name"));
  var [Date1, setDate1] = useState(new Date());
  const [Date2, setDate2] = useState(Date1.toISOString().slice(0, 10));
  const [part_dataset, setpart_dataset] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  var length = 0;

  const [data, setData] = useState([
    { Po_No: '', Part_No: '', Po_Ship_Date: '', Part_Description: '', Customer: '', Qty: '', Po_Date: Date2, Comments: '' },
    { Po_No: '', Part_No: '', Po_Ship_Date: '', Part_Description: '', Customer: '', Qty: '', Po_Date: '', Comments: '' },
    { Po_No: '', Part_No: '', Po_Ship_Date: '', Part_Description: '', Customer: '', Qty: '', Po_Date: '', Comments: '' },
    { Po_No: '', Part_No: '', Po_Ship_Date: '', Part_Description: '', Customer: '', Qty: '', Po_Date: '', Comments: '' },
    { Po_No: '', Part_No: '', Po_Ship_Date: '', Part_Description: '', Customer: '', Qty: '', Po_Date: '', Comments: '' }
  ]);


  // Hook to update the date as today's date
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });

  useEffect(() => {
    Axios.get(`https://` + host + `/api/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data);
    });
  }, []);

  useEffect(() => {
    const newData = data.map((line) => ({ ...line, Date: Date2 }));
    setData(newData);
  }, [Date2])

  const submitValue = (e, index) => {
    const newData = [...data];
    newData[index].Part_No = e.target.value;
    newData[index].Customer = (part_dataset.find((x) => x.Part_No === e.target.value).Customer);
    newData[index].Part_Description = part_dataset.find((x) => x.Part_No === e.target.value).Part_Description;
    setData(newData);
  }

  const addLines = () => {
    if (data.length < 15) {
      const newData = [...data];
      for (let i = 0; i < 5; i++) {
        newData.push({ Po_No: '', Part_No: '', Po_Ship_Date: '', Part_Description: '', Customer: '', Qty: '', Po_Date: '', Comments: '' });
      }
      setData(newData);
    } else {
      window.alert("Lines Reached maximum limit")
    }

  }
  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    props.history.push('/');
  }
  const userMenu = (
    <Menu>
      <Menu.Item key="logout">
        <a onClick={handleLogout} href="/">
          <LogoutOutlined />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const submitdata = () => {
    const filteredData = [];

    data.forEach(item => {
      if (
        item.Po_No &&
        item.Part_No &&
        item.Customer &&
        item.Part_Description &&
        item.Po_Ship_Date
      ) {
        const newData = [
          item.Po_No,
          item.Part_No,
          item.Po_Ship_Date,
          item.Part_Description,
          item.Customer,
          item.Qty,
          item.Po_Date,
          item.Comments

        ];
        filteredData.push(newData);
      }
      length = filteredData.length;
    });
    setIsLoading(true);

    setTimeout(() => {
      Axios.post(`https://` + host + `/api/moulding_entry`, { filteredData })
        .then((response) => {
          if (response.status === 200) {
            window.alert(response.data.message);
            setIsLoading(false);
            window.location.reload();

          }
        })
        .catch(error => {
          window.alert("Error Occured")
          setIsLoading(false);
        });
    }, 2000);

    console.log(filteredData);
    // window.location.reload(false);
  }
  return (
    <div>
      <br></br>
      <button onClick={addLines} class="btn btn-success">Add Row</button>
      <br></br>
      <br></br>

      <table class="table table-bordered">
        <tbody>
          <tr>
            <th>Po No</th>
            <th>Part No</th>
            <th>Po Ship Date</th>
            <th>Part Description</th>
            <th>Customer</th>
            <th>Qty</th>
            <th>Po Date</th>
            <th>Comments</th>
          </tr>
          {data.map((line, index) => (
            <tr>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    if (index === 0) {
                      for (let i = 0; i < newData.length; i++) {
                        newData[i].Po_No = e.target.value;
                      }
                    }
                    else {
                      newData[index].Po_No = e.target.value;
                    }
                    setData(newData);
                  }}
                  Value={data[0].Po_No}
                  placeholder="Po No"
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Part_No = e.target.value;
                    setData(newData);
                  }}
                  onBlur={(e) => submitValue(e, index)} // Call the submitValue function on blur
                  list="PartNo"
                  id="operator"
                  placeholder="Enter Part No"
                />
                <div className="scrollbar">
                  <datalist id="PartNo">
                    <option></option>
                    {part_dataset.map((x) => {
                      return <option>{x.Part_No}</option>;
                    })}
                  </datalist>
                </div>
              </td>
              <td>
                <input
                  type="date"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];

                    newData[index].Po_Ship_Date = e.target.value;

                    setData(newData);
                  }}
                  placeholder="Po Date"
                  Value={data[0].Po_Ship_Date}
                  name="target"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={data[index].Part_Description}
                  placeholder="Part Description"
                  readOnly
                  required

                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  value={data[index].Customer}
                  placeholder="Customer"
                  readOnly

                />
              </td>
              <td >
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Qty = e.target.value;
                    setData(newData);
                  }}
                  placeholder="Qty"
                />
              </td>
              <td>
                <input
                  type="date"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Po_Date = e.target.value;

                    setData(newData);
                  }}
                  Value={data[0].Po_Date}
                  placeholder="Po Date"
                  name="target"
                  required
                />
              </td>
              <td>
                <input
                  type="text"
                  className="form-control form-rounded"
                  onChange={(e) => {
                    const newData = [...data];
                    newData[index].Comments = e.target.value;
                    setData(newData);
                  }}
                  placeholder="Comments"
                  name="Supervisor_notes"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={submitdata} class="btn btn-primary">Submit</button>
      {isLoading ?
        <div className='overlay'>
          <div className="loading-container">
            <div className="loading-dots">
              <div className="dot"></div>
              <div className="dot"></div>
              <div className="dot"></div>
            </div>
            <p className='loading'>Submitting</p>
          </div>

        </div> : null}
    </div>
  )
}

export default Moulding_entry
import React, { useState, useEffect } from "react";
import Axios from "axios";
import { Link, Redirect, useParams } from "react-router-dom";
import "./MainPage.css";
import WooShop from "./WoodShop";
import { Host } from "../App";
import { newData } from "../Pivot/Pivot";

function Moulding() {


  const [part_dataset, setpart_dataset] = useState([]);
  var [Selectdd1, setSelectdd1] = useState("");
  var [Date1, setDate1] = useState(new Date());
  const [Date2, setDate2] = useState(Date1.toISOString().slice(0, 10));

  var [Po_No, setPo_No] = useState("");
  const [Part_No, setPart_No] = useState({ Part_No: {} });
  var [Po_Ship_Date, setPo_Ship_Date] = useState(
    Date1.toISOString().slice(0, 10)
  );
  const [Part_Description, setPart_Description] = useState({
    Part_Description: [],
  });
  const [Customer, setCustomer] = useState({ Customer: [] });
  const [Moulding, setMoulding] = useState("");
  const [Woodshop, setWoodshop] = useState("");
  const [Upholstery, setUpholstery] = useState("");
  var [Qty, setQty] = useState("");
  var [Po_Date, setPo_Date] = useState(Date1.toISOString().slice(0, 10));
  const [Unit_Price, setUnit_Price] = useState("");
  const [Comments, setComments] = useState("");

  var [Po_No1, setPo_No1] = useState("");
  const [Part_No1, setPart_No1] = useState("");
  var [Po_Ship_Date1, setPo_Ship_Date1] = useState(
    Date1.toISOString().slice(0, 10)
  );
  const [Part_Description1, setPart_Description1] = useState({
    Part_Description: [],
  });
  const [Customer1, setCustomer1] = useState({ Customer: [] });
  var [Qty1, setQty1] = useState("");
  var [Po_Date1, setPo_Date1] = useState(Po_Date);
  const [Unit_Price1, setUnit_Price1] = useState("");
  const [Comments1, setComments1] = useState("");
  const [Moulding1, setMoulding1] = useState("");
  const [Woodshop1, setWoodshop1] = useState("");
  const [Upholstery1, setUpholstery1] = useState("");

  var [Po_No2, setPo_No2] = useState("");
  const [Part_No2, setPart_No2] = useState("");
  var [Po_Ship_Date2, setPo_Ship_Date2] = useState(
    Date1.toISOString().slice(0, 10)
  );
  const [Part_Description2, setPart_Description2] = useState({
    Part_Description: [],
  });
  const [Customer2, setCustomer2] = useState({ Customer: [] });
  var [Qty2, setQty2] = useState("");
  const [Po_Date2, setPo_Date2] = useState(Po_Date);
  const [Unit_Price2, setUnit_Price2] = useState("");
  const [Comments2, setComments2] = useState("");
  const [Moulding2, setMoulding2] = useState("");
  const [Woodshop2, setWoodshop2] = useState("");
  const [Upholstery2, setUpholstery2] = useState("");

  var [Po_No3, setPo_No3] = useState("");
  const [Part_No3, setPart_No3] = useState("");
  var [Po_Ship_Date3, setPo_Ship_Date3] = useState(
    Date1.toISOString().slice(0, 10)
  );
  const [Part_Description3, setPart_Description3] = useState({
    Part_Description: [],
  });
  const [Customer3, setCustomer3] = useState({ Customer: [] });
  var [Qty3, setQty3] = useState("");
  const [Po_Date3, setPo_Date3] = useState(Po_Date);
  const [Unit_Price3, setUnit_Price3] = useState("");
  const [Comments3, setComments3] = useState("");
  const [Moulding3, setMoulding3] = useState("");
  const [Woodshop3, setWoodshop3] = useState("");
  const [Upholstery3, setUpholstery3] = useState("");

  var [Po_No4, setPo_No4] = useState("");
  const [Part_No4, setPart_No4] = useState("");
  var [Po_Ship_Date4, setPo_Ship_Date4] = useState(
    Date1.toISOString().slice(0, 10)
  );
  const [Part_Description4, setPart_Description4] = useState({
    Part_Description: [],
  });
  const [Customer4, setCustomer4] = useState({ Customer: [] });
  var [Qty4, setQty4] = useState("");
  const [Po_Date4, setPo_Date4] = useState(Po_Date);
  const [Unit_Price4, setUnit_Price4] = useState("");
  const [Comments4, setComments4] = useState("");
  const [Moulding4, setMoulding4] = useState("");
  const [Woodshop4, setWoodshop4] = useState("");
  const [Upholstery4, setUpholstery4] = useState("");

  var token = (window.localStorage.getItem("acessToken"));

  // Hook to update the date as today's date
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10);
    return function cleanup() {
      clearInterval(timer);
    };
  }, []);
  // to retreive Machines
  useEffect(() => {
    Axios.get(`https://` + Host + `/api/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data);
    });
  }, []);
  const submitValue = (e) => {
    setPart_No({ Part_No: e.target.value });
    setCustomer({
      Customer: part_dataset.find((x) => x.Part_No === e.target.value).Customer,
    });
    setPart_Description({
      Part_Description: part_dataset.find((x) => x.Part_No === e.target.value)
        .Part_Description,
    });
    setMoulding({
      Moulding: part_dataset.find((x) => x.Part_No === e.target.value).MOLDING,
    });
    setWoodshop({
      Woodshop: part_dataset.find((x) => x.Part_No === e.target.value).WOODSHOP,
    });
    setUpholstery({
      Upholstery: part_dataset.find((x) => x.Part_No === e.target.value)
        .UPHOLSTERY,
    });
    //console.log(JSON.parse(JSON.stringify(Moulding)));
  };
  const submitValue1 = (e) => {
    console.log(Moulding.Moulding);
    console.log(Woodshop.Woodshop);
    console.log(Upholstery.Upholstery);

    setPart_No1({ Part_No: e.target.value });
    setCustomer1({
      Customer: part_dataset.find((x) => x.Part_No === e.target.value).Customer,
    });
    setPart_Description1({
      Part_Description: part_dataset.find((x) => x.Part_No === e.target.value)
        .Part_Description,
    });
    setMoulding1({
      Moulding: part_dataset.find((x) => x.Part_No === e.target.value).MOLDING,
    });
    setWoodshop1({
      Woodshop: part_dataset.find((x) => x.Part_No === e.target.value).WOODSHOP,
    });
    setUpholstery1({
      Upholstery: part_dataset.find((x) => x.Part_No === e.target.value)
        .UPHOLSTERY,
    });
  };
  const submitValue2 = (e) => {
    setPart_No2({ Part_No: e.target.value });
    setCustomer2({
      Customer: part_dataset.find((x) => x.Part_No === e.target.value).Customer,
    });
    setPart_Description2({
      Part_Description: part_dataset.find((x) => x.Part_No === e.target.value)
        .Part_Description,
    });
    setMoulding2({
      Moulding: part_dataset.find((x) => x.Part_No === e.target.value).MOLDING,
    });
    setWoodshop2({
      Woodshop: part_dataset.find((x) => x.Part_No === e.target.value).WOODSHOP,
    });
    setUpholstery2({
      Upholstery: part_dataset.find((x) => x.Part_No === e.target.value)
        .UPHOLSTERY,
    });
  };
  const submitValue3 = (e) => {
    setPart_No3({ Part_No: e.target.value });
    setCustomer3({
      Customer: part_dataset.find((x) => x.Part_No === e.target.value).Customer,
    });
    setPart_Description3({
      Part_Description: part_dataset.find((x) => x.Part_No === e.target.value)
        .Part_Description,
    });
    setMoulding3({
      Moulding: part_dataset.find((x) => x.Part_No === e.target.value).MOLDING,
    });
    setWoodshop3({
      Woodshop: part_dataset.find((x) => x.Part_No === e.target.value).WOODSHOP,
    });
    setUpholstery3({
      Upholstery: part_dataset.find((x) => x.Part_No === e.target.value)
        .UPHOLSTERY,
    });
  };
  const submitValue4 = (e) => {
    setPart_No4({ Part_No: e.target.value });
    setCustomer4({
      Customer: part_dataset.find((x) => x.Part_No === e.target.value).Customer,
    });
    setPart_Description4({
      Part_Description: part_dataset.find((x) => x.Part_No === e.target.value)
        .Part_Description,
    });
    setMoulding4({
      Moulding: part_dataset.find((x) => x.Part_No === e.target.value).MOLDING,
    });
    setWoodshop4({
      Woodshop: part_dataset.find((x) => x.Part_No === e.target.value).WOODSHOP,
    });
    setUpholstery4({
      Upholstery: part_dataset.find((x) => x.Part_No === e.target.value)
        .UPHOLSTERY,
    });
  };

  const submitPost = () => {
    const data1 = [
      Po_No1,
      Part_No1,
      Po_Ship_Date1,
      JSON.parse(JSON.stringify(Part_Description1.Part_Description)),
      JSON.parse(JSON.stringify(Customer1.Customer)),
      Qty1,
      Po_Date1,
      Unit_Price1,
      Comments1,
      Moulding1.Moulding,
      Woodshop1.Woodshop,
      Upholstery1.Upholstery,
    ];
    const data2 = [
      Po_No2,
      Part_No2,
      Po_Ship_Date2,
      JSON.parse(JSON.stringify(Part_Description2.Part_Description)),
      JSON.parse(JSON.stringify(Customer2.Customer)),
      Qty2,
      Po_Date2,
      Unit_Price2,
      Comments2,
      Moulding2.Moulding,
      Woodshop2.Woodshop,
      Upholstery2.Upholstery,
    ];
    const data3 = [
      Po_No3,
      Part_No3,
      Po_Ship_Date3,
      JSON.parse(JSON.stringify(Part_Description3.Part_Description)),
      JSON.parse(JSON.stringify(Customer3.Customer)),
      Qty3,
      Po_Date3,
      Unit_Price3,
      Comments3,
      Moulding3.Moulding,
      Woodshop3.Woodshop,
      Upholstery3.Upholstery,
    ];
    const data4 = [
      Po_No4,
      Part_No4,
      Po_Ship_Date4,
      JSON.parse(JSON.stringify(Part_Description4.Part_Description)),
      JSON.parse(JSON.stringify(Customer4.Customer)),
      Qty4,
      Po_Date4,
      Unit_Price4,
      Comments4,
      Moulding4.Moulding,
      Woodshop4.Woodshop,
      Upholstery4.Upholstery,
    ];

    Axios.post(`https://` + Host + `/api/create`, {
      Po_No: Po_No,
      Part_No: Part_No,
      Po_Ship_Date: Po_Ship_Date,
      Part_Description: JSON.parse(
        JSON.stringify(Part_Description.Part_Description)
      ),
      Customer: JSON.parse(JSON.stringify(Customer.Customer)),
      Qty: Qty,
      Po_Date: Po_Date,
      Unit_Price: Unit_Price,
      Comments: Comments,
      Moulding: Moulding.Moulding,
      Woodshop: Woodshop.Woodshop,
      Upholstery: Upholstery.Upholstery,
      data1,
      data2,
      data3,
      data4,
      token
    });

    //window.location.reload(false);
    //window.setTimeout(function () { location.reload() }, 3000)
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };

  return (
    <div className="Upholstery_data">
      <br></br>

      <table class="table table-bordered">
        <thead>
          <tr>
            <th>PO NO</th>
            <th>PART NO</th>
            <th>PO SHIP DATE</th>
            <th>PART DESCRIPTION</th>
            <th>CUSTOMER</th>
            <th>QTY</th>
            <th>PO DATE</th>
            <th>COMMENTS</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <input
                type="text"
                name="po no"
                onBlur={(e) => {
                  setPo_No(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="part no"
                onBlur={(e) => {
                  setPart_No(e.target.value);
                }}
                list="InternalPartNo"
                class="form-control form-rounded"
                onChange={submitValue.bind(this)}
              />
              <div class="scrollbar">
                <datalist id="InternalPartNo">
                  <option></option>
                  {part_dataset.map((x) => {
                    return <option>{x.Part_No}</option>;
                  })}
                </datalist>
              </div>
            </td>
            <td>
              <input
                type="date"
                name="po ship date"
                defaultValue={Date1.toISOString().slice(0, 10)}
                onBlur={(e) => {
                  setPo_Ship_Date(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="part description"
                value={Part_Description.Part_Description}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="customer"
                value={Customer.Customer}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="qty"
                onBlur={(e) => {
                  setQty(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="date"
                name="po date"
                defaultValue={Date1.toISOString().slice(0, 10)}
                onBlur={(e) => {
                  setPo_Date(e.target.value);
                  setPo_Date1(e.target.value);
                  setPo_Date2(e.target.value);
                  setPo_Date3(e.target.value);
                  setPo_Date4(e.target.value);


                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                id="textbox"
                name="comments"
                onBlur={(e) => {
                  setComments(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                name="po no"
                onBlur={(e) => {
                  setPo_No1(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="part no"
                onBlur={(e) => {
                  setPart_No1(e.target.value);
                }}
                list="InternalPartNo"
                class="form-control form-rounded"
                onChange={submitValue1.bind(this)}
              />
              <div class="scrollbar">
                <datalist id="InternalPartNo">
                  <option></option>
                  {part_dataset.map((x) => {
                    return <option>{x.Part_No}</option>;
                  })}
                </datalist>
              </div>
            </td>
            <td>
              <input
                type="date"
                name="po ship date"
                defaultValue={Date1.toISOString().slice(0, 10)}
                onBlur={(e) => {
                  setPo_Ship_Date1(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="part description"
                value={Part_Description1.Part_Description}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="customer"
                value={Customer1.Customer}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="qty"
                onBlur={(e) => {
                  setQty1(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="date"
                name="po date"
                Value={Po_Date}
                onChange={(e) => {
                  setPo_Date1(e.target.value);
                }}
                onBlur={(e) => {
                  setPo_Date1(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                id="textbox"
                name="comments"
                onBlur={(e) => {
                  setComments1(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                name="po no"
                onBlur={(e) => {
                  setPo_No2(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="part no"
                onBlur={(e) => {
                  setPart_No2(e.target.value);
                }}
                list="InternalPartNo"
                class="form-control form-rounded"
                onChange={submitValue2.bind(this)}
              />
              <div class="scrollbar">
                {" "}
                <datalist id="InternalPartNo">
                  {" "}
                  <option></option>
                  {part_dataset.map((x) => {
                    return <option>{x.Part_No}</option>;
                  })}{" "}
                </datalist>{" "}
              </div>
            </td>
            <td>
              <input
                type="date"
                name="po ship date"
                defaultValue={Date1.toISOString().slice(0, 10)}
                onBlur={(e) => {
                  setPo_Ship_Date2(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="part description"
                value={Part_Description2.Part_Description}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="customer"
                value={Customer2.Customer}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="qty"
                onBlur={(e) => {
                  setQty2(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="date"
                name="po date"
                Value={Po_Date}
                onBlur={(e) => {
                  setPo_Date2(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                id="textbox"
                name="comments"
                onBlur={(e) => {
                  setComments2(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                name="po no"
                onBlur={(e) => {
                  setPo_No3(e.target.value);
                }}
                class="form-control form-rounded"
              />{" "}
            </td>
            <td>
              <input
                type="text"
                name="part no"
                onBlur={(e) => {
                  setPart_No3(e.target.value);
                }}
                list="InternalPartNo"
                class="form-control form-rounded"
                onChange={submitValue3.bind(this)}
              />{" "}
              <div class="scrollbar">
                <datalist id="InternalPartNo">
                  <option></option>{" "}
                  {part_dataset.map((x) => {
                    return <option>{x.Part_No}</option>;
                  })}{" "}
                </datalist>
              </div>
            </td>
            <td>
              <input
                type="date"
                name="po ship date"
                defaultValue={Date1.toISOString().slice(0, 10)}
                onBlur={(e) => {
                  setPo_Ship_Date3(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="part description"
                value={Part_Description3.Part_Description}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="customer"
                value={Customer3.Customer}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="qty"
                onBlur={(e) => {
                  setQty3(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="date"
                name="po date"
                Value={Po_Date}
                onBlur={(e) => {
                  setPo_Date3(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                id="textbox"
                name="comments"
                onBlur={(e) => {
                  setComments3(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
          </tr>
          <tr>
            <td>
              <input
                type="text"
                name="po no"
                onBlur={(e) => {
                  setPo_No4(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="part no"
                onBlur={(e) => {
                  setPart_No4(e.target.value);
                }}
                list="InternalPartNo"
                class="form-control form-rounded"
                onChange={submitValue4.bind(this)}
              />
              <div class="scrollbar">
                <datalist id="InternalPartNo">
                  <option></option>
                  {part_dataset.map((x) => {
                    return <option>{x.Part_No}</option>;
                  })}
                </datalist>
              </div>
            </td>
            <td>
              <input
                type="date"
                name="po ship date"
                defaultValue={Date1.toISOString().slice(0, 10)}
                onBlur={(e) => {
                  setPo_Ship_Date4(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="part description"
                value={Part_Description4.Part_Description}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="customer"
                value={Customer4.Customer}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                name="qty"
                onBlur={(e) => {
                  setQty4(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="date"
                name="po date"
                Value={Po_Date}
                onBlur={(e) => {
                  setPo_Date4(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
            <td>
              <input
                type="text"
                id="textbox"
                name="comments"
                onBlur={(e) => {
                  setComments4(e.target.value);
                }}
                class="form-control form-rounded"
              />
            </td>
          </tr>
        </tbody>
      </table>
      <div>
        <input
          type="button"
          class="btn btn-success"
          defaultValue="Submit"
          name="Submit"
          id="submitbtn"
          onClick={submitPost}
        />
        <Link to="/moldingentry">
          <input
            type="button"
            class="btn btn-success"
            defaultValue="New Molding entry"
            id="submitbtn"
            name="Section"
          />
        </Link>
      </div>

    </div>
  );
}

export default Moulding;

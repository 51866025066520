import logo from "./logo.svg";
import {
  BrowserRouter,
  Switch,
  Route,
  Router,
  withRouter,
  useLocation
} from "react-router-dom";
import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import Header from "./Header";
import axios from "axios";
import { getToken, removeUserSession, setUserSession } from "./Utils/Common";
import PrivateRoute from "./Utils/PrivateRoute";
import "./OpenOrders.css";
import ShippedData from "./ShippedData";
import OpenOrders from "./OpenOrders";
import AddData from "./AddData";
import Dashboard from "./Dashboard";
import Login from "./Login";
import './Responsive.css';
import UpdatePOData from "./UpdatePOData";
import Add_Part_Dataset from "./Add_part_Dataset/Add_Part_Dataset";
import Morning_registration from "./aws_project/Morning_registration";
import MainPage from "./aws_project/Pages/MainPage";
import GetReport from "./aws_project/Pages/GetReport";
import { Data } from "./Pivot/Pivot";
import NewDashboard from "./NewDashboard/Dashboardnew";
import MultiLineChart from "./NewDashboard/LineGraph";
import VideoPlayer from "./Video_project/Video_Main";
import Production_entry from "./aws_project/Production_entry";
import Moulding_entry from "./Pages/Moulding_entry";
import Attendance from "./attendance_system/Attendance";
import Supply_chain_dashboard from "./Supply_chain/Supply_chain_dashboard";
import History_Received_raw_Material from "./Supply_chain/PO_Raw_material/History_Received_raw_Material";
import Attendance_details from "./attendance_system/Attendance_details";
import Raw_Material_Dataset from "./Supply_chain/Other_Expenses.js/Raw_material_dataset/Raw_Material_Dataset";
import History_Received_Supplies from "./Supply_chain/PO_Supplies/History_Received_Supples";
import { Data_Supply } from "./Supply_chain/Pivot_supply_chain/Pivot";
import Sidebar_SuppluChain from "./Supply_chain/Dashboard";
import Schedule from "./aws_project/Schedule/Create_Schedule";
import Scheduling from "./aws_project/Schedule/Scheduling";
import Admin_dashboard from "./Admin_dashboard/Admin_dashboard";
import AdminRoute from "./Utils/AdminRoute";
import BOM_Dashboard from "./BOM/BOM_Dashboard";
import BOM_pivot from "./BOM/BOM";
import BOM_entry from "./BOM/Add_BOM";
import ManagerRoute from "./Utils/ManagerRoute";
import Footer from "./Footer/Footer";
import Attendance_report from "./attendance_system/Attendance_report";
import Mold_list from "./aws_project/Production/Mold_list";
import Production_dashboard from "./aws_project/Production/Production_dashboard";
import HamburgerMenu from "./MobileView/HamburgerMenu";
import New_ShiftReport from "./aws_project/Production/New_Shift_Reports";
import ShiftReport_All from "./aws_project/Production/All_Shift_Reports";
import Trial from "./aws_project/Production/Storage/Trial";
import Upholstery_Schedule from "./Pages/Upholstery_schedule";

export const Host = '18.117.194.170';
export const host = '18.117.194.170';
export var token = (window.localStorage.getItem("acessToken"));


const App = () => {
  const location = useLocation();
  const hideFooter = location.pathname === '/login' || location.pathname === '/';
  const [PD, setPD] = useState(false);

  const [authLoading, setAuthLoading] = useState(true);
  useEffect(() => {
    const token = getToken();
    if (!token) {
      setAuthLoading(false);
      return;
    }

    axios
      .get(`https://` + Host + `/verifyToken?token=${token}`)
      .then((response) => {
        setUserSession(response.data.token, response.data.user);
      })
      .catch((error) => {
        removeUserSession();
      })
      .finally(() => {
        setAuthLoading(false);
      });
  }, []);

  if (authLoading && getToken()) {
    return <div className="content">Checking Authentication...</div>;
  }

  return (
    <div className="App">

      <div class="headers" id="h">
        <Header />
      </div>
      <div id="threeLines">

        {!hideFooter && <HamburgerMenu />}
      </div>
      <Switch>
        <Route exact path="/" component={Login} />
        <PrivateRoute exact path="/pivot" component={Data} />
        <PrivateRoute path="/Getreport" component={GetReport} />
        <PrivateRoute path="/moldingentry" component={Moulding_entry} />
        <PrivateRoute path="/NewDashboard" component={NewDashboard} />
        <PrivateRoute path="/Adddata" component={AddData} />
        <PrivateRoute path="/Dashboard" component={withRouter(Dashboard)} />
        <PrivateRoute path="/reaminedparts" component={OpenOrders} />
        <PrivateRoute path="/shippedparts" component={ShippedData} />
        <PrivateRoute path="/UpdatePOData" component={UpdatePOData} />
        <PrivateRoute path="/Night" component={Morning_registration} />
        <PrivateRoute path="/Nightdata" component={Morning_registration} />
        <PrivateRoute path="/RetrieveData" component={MainPage} />
        <PrivateRoute path="/openorder" component={OpenOrders} />
        <Route path="/login" component={Login} />
        <PrivateRoute path="/Morning" component={Production_entry} />
        <PrivateRoute path="/graph" component={MultiLineChart} />
        <PrivateRoute path="/attendance" component={Attendance} />
        <PrivateRoute path="/attendanceDetails" component={Attendance_details} />

        <ManagerRoute path="/rawmaterial" component={Raw_Material_Dataset} adminOnly alternate="/NewDashboard" />
        <ManagerRoute path="/Historyrawmaterial" component={History_Received_raw_Material} adminOnly alternate="/NewDashboard" />
        <ManagerRoute path="/Historyreceivedsupplies" component={History_Received_Supplies} adminOnly alternate="/NewDashboard" />
        <ManagerRoute path="/Supply_Pivot" component={Data_Supply} adminOnly alternate="/NewDashboard" />
        <ManagerRoute path="/Suppluchain" component={Sidebar_SuppluChain} adminOnly alternate="/NewDashboard" />
        <ManagerRoute path="/attendancereport" component={Attendance_report} adminOnly alternate="/attendance" />
        <ManagerRoute path="/addpartdataset" component={Add_Part_Dataset} adminOnly alternate="/attendance" />


        <PrivateRoute path="/Scheduling" component={Scheduling} />
        <PrivateRoute path="/SchedulingEntry" component={Schedule} />

        <AdminRoute path="/Admin_dashboard" component={Admin_dashboard} adminOnly alternate="/NewDashboard" />
        <AdminRoute path="/BOM" component={BOM_Dashboard} adminOnly alternate="/NewDashboard" />
        <AdminRoute path="/BOMPivot" component={BOM_pivot} adminOnly alternate="/NewDashboard" />
        <AdminRoute path="/Add_BOM" component={BOM_entry} adminOnly alternate="/NewDashboard" />

        <PrivateRoute path="/Production/MoldList" component={Mold_list} />
        <Route path="/Production/shiftReport" component={New_ShiftReport} />
        <Route path="/Production/shiftReports/All" component={ShiftReport_All} />
        <PrivateRoute path="/Production" component={Production_dashboard} />

        {/*Trial Routes*/}
        <Route path="/trial" component={Trial} />
        <Route path="/Upholstery_Schedule" component={Upholstery_Schedule} />
      </Switch>

      {!hideFooter && <Footer />}
    </div>
  );
}

export default withRouter(App);

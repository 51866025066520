import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import { Host } from '../App';
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import './Attendance.css';
import Attendance_dashboard from './Attendance_dashboard';
import Vaccation from './Vaccation';
import { Link } from 'react-router-dom';
function Attendance() {
  const [selectedShift, setSelectedShift] = useState("");
  const [shiftSelection, setshiftSelection] = useState(false);
  const [rank, setrank] = useState(false);
  var Ranked = (window.localStorage.getItem("Rank"));
  var token = (window.localStorage.getItem("acessToken"));
  var token = (window.localStorage.getItem("acessToken"));
  var user = (window.localStorage.getItem("Name"));
  const [employees, setemployees] = useState([]);
  const [leave_type, setleave_type] = useState([]);
  var [selectedLeaveType, setSelectedLeaveType] = useState('');
  const [Attendance_dashboard_sec, setAttendance_dashboard_sec] = useState(true);
  const [attendanceList, setAttendanceList] = useState([]);
  const [shift, setshift] = useState({});
  const [Date1, setDate1] = useState(new Date());
  const [Today, setToday] = useState(Date1.toISOString().slice(0, 10));
  const [Vaccation_sec, setvaccation_sec] = useState(false);
  const handleShiftChange = (event) => {
    setSelectedShift(event.target.value);
    setshift(event.target.value);
    setvaccation_sec(false);
    setshiftSelection(true);
    setAttendance_dashboard_sec(false);

  };
  const queryParams = {
    shift: shift,
    token: token,
    Rank: Ranked,
    Date: Today,
    user: user
  };
  //for today's date
  useEffect(() => {
    var timer = setInterval(() => setDate1(new Date()), 10)
    return function cleanup() {
      clearInterval(timer)
    }
  });
  useEffect(() => {
    if (Ranked === '"Admin"') {
      setrank(true);
    }
  }, [Ranked]);

  useEffect(() => {
    if (shift.length > 1) {
      Axios.get(`https://` + Host + `/api/employees`, { params: queryParams })
        .then((data) => {
          const filteredEmployees = data.data.filter((employee) => employee.Name && employee.Name.length > 1);
          const initialAttendanceList = filteredEmployees.map((employee) => ({
            Date: Today,
            id: employee.Emp_ID,
            Employee_Name: employee.Name,
            Department: selectedShift,
            Status: 'present',
            LeaveType: '',
            Details: '',
            user: user
          }));
          setemployees(filteredEmployees);
          setAttendanceList(initialAttendanceList);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [shift, Today]);

  useEffect(() => {
    Axios.get(`https://` + Host + `/api/leave_type`, { params: queryParams }).then((data) => {
      setleave_type(data.data);
    });
  }, [shift]);

  const handleRadioChange = (id, type) => {
    setAttendanceList((prevList) =>
      prevList.map((item) =>
        item.id === id
          ? {
            ...item,
            Status: type,
            Details: type === 'absent' ? '' : item.Details,
          }
          : item
      )
    );
  };

  const handleLeaveTypeChange = (id, value) => {
    setAttendanceList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, LeaveType: value } : item
      )
    );
  };
  const handleDetailsChange = (id, value) => {
    setAttendanceList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, Details: value } : item
      )
    );
  };

  const vac = () => {
    setvaccation_sec(!Vaccation_sec);
    setshiftSelection(false);
    setAttendance_dashboard_sec(false);
  }
  const handleSubmit_trial = () => {
    // Handle submission logic here
    Axios.post(`https://` + Host + `/api/addattendance`, {
      attendanceList
    }).then((response) => {
      if (response.status === 200) {
        toast.success(response.data.message, {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
          style: { fontSize: '24px', borderRadius: '10px' },
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
      if (response.status === 500) {
        toast.success(response.data.message, {
          autoClose: 3000,
          position: toast.POSITION.TOP_RIGHT,
          style: { fontSize: '24px', borderRadius: '10px' },
        });
        setTimeout(function () {
          window.location.reload();
        }, 3000);
      }
    })
  };
  const handleSubmit = () => {
    // Handle submission logic here
    Axios.post(`https://` + Host + `/api/addattendance`, {
      attendanceList, token: token
    });
    setTimeout(function () {
      window.location.reload();
    }, 2000);
  };
  useEffect(() => {
    // This effect will run after the initial render
    section_selected(selectedShift);
  }, [selectedShift]);

  const section_selected = (shiftType) => {
    // This function does not trigger state updates directly
    switch (shiftType) {
      case 'Morning_Operators':
        setSelectedLeaveType('Morning Shift');
        break;
      case 'Night_Operators':
        setSelectedLeaveType('Night Shift');
        break;
      case 'Woodshop':
        setSelectedLeaveType('Woodshop');
        break;
      case 'Upholstery':
        setSelectedLeaveType('Upholstery');
        break;
      case 'Management':
        setSelectedLeaveType('Management');
        break;
      default:
        setSelectedLeaveType('');
        break;
    }
  };
  return (
    <div>
      <center>
        <h1>Attendance Portal</h1>
        <p id='title'><b>{selectedLeaveType}</b></p>

      </center>
      <div class='table'>
        <div class='row' id="Attendance_filters">
          <div class="col-2">
            <select
              name="shift"
              className="form-control form-rounded"
              placeholder="Select Shift"
              value={selectedShift}
              onChange={handleShiftChange}
            >
              <option value="">--Select Shift--</option>
              <option value="Morning_Operators">Morning Shift</option>
              <option value="Night_Operators">Night Shift</option>
              <option value="Woodshop">Woodshop/Upholstery</option>
              {rank ? <option value="Management">Management</option> : null}
            </select>
          </div>
          <div class='col-2'>
            <input type='date' class="form-control form-rounded" placeholder='Date' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
              setToday(e.target.value)
            }} />
          </div>
          <div class="col-2">
            <Link to="/attendanceDetails">
              <button class="btn btn-light" id="enter_vaccation" onClick="/attendanceDetails">Attendance Details</button>
            </Link>
          </div>
          <div class="col-1">
            <Link to="/addpartdataset">
              <button class="btn btn-light" id="enter_vaccation" onClick="/addpartdataset">Employees</button>
            </Link>
          </div>
          <div class="col-2">
            <Link to='/attendancereport'>
              <button class="btn btn-light" id="enter_vaccation">Attendance Report</button>
            </Link>
          </div>
          <div class="col-1">
            <button class="btn btn-light" id="enter_vaccation" onClick={vac}>Vacation Portal</button>
          </div>
          <div class="col-2" id="mobileView">
            <div class="block_user">
              <h2>{user.replace(/[{""}]/g, "")}</h2>
            </div>
          </div>
          {Attendance_dashboard_sec ? <Attendance_dashboard /> : null}
          {Vaccation_sec ? <Vaccation /> : null}

        </div>
      </div>
      {shiftSelection ? <div>
        <table class="table table-striped">
          <table class="table table-striped" id="attendance_table">
            <thead class="attendance_table_head">
              <tr class="attendance_table_head">
                <th scope="col">Name</th>
                <th scope="col">Present</th>
                <th scope="col">Absent</th>
              </tr>
            </thead>
            <tbody>
              {attendanceList.map((item) => (
                <tr key={item.id} >
                  <td >{item.Employee_Name}</td>
                  <td>
                    <input
                      type="radio"
                      checked={item.Status === 'present'}
                      name={`Status_${item.id}`}
                      onChange={() => handleRadioChange(item.id, 'present')}
                    />
                  </td>
                  <td>
                    <input
                      type="radio"
                      checked={item.Status === 'absent'}
                      name={`Status_${item.id}`}
                      onChange={() => handleRadioChange(item.id, 'absent')}
                    />
                  </td>

                  <td>
                    {item.Status == 'absent' && (
                      <div>
                        <select name="LeaveType" class="form-control form-rounded" placeholder="Select Reason" readOnly value=
                          {item.LeaveType || ''} onChange={(e) => handleLeaveTypeChange(item.id, e.target.value)} >
                          <option value="">Select Reason</option>
                          {leave_type.map((x) => (
                            <option key={x.type} value={x.type}>
                              {x.type}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                    {item.Status == 'present' && (
                      <div>
                        <select name="LeaveType" class="form-control form-rounded" placeholder="Select Reason present" readOnly value=
                          {item.LeaveType || ''} onChange={(e) => handleLeaveTypeChange(item.id, e.target.value)} >
                          <option value="">Select Reason</option>
                          {leave_type.map((x) => (
                            <option key={x.Present} value={x.Present}>
                              {x.Present}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
                  </td>
                  <td>
                    <input type='text' class="form-control form-rounded" placeholder='Details' value=
                      {item.Details || ''} onChange={(e) => handleDetailsChange(item.id, e.target.value)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <button class="btn btn-success" onClick={handleSubmit}>Submit</button>
        </table>
      </div> : null}
    </div>
  )
}

export default Attendance
import React, { useState, useEffect } from 'react'
import Axios from 'axios'
import '../Registration.css';
import { host } from '../../App';
import { UserOutlined, LogoutOutlined } from '@ant-design/icons';
import { Button, Menu, Dropdown, Row } from 'antd';
import { Link } from "react-router-dom";
import { format } from 'date-fns';
import { LocalFireDepartment } from '@mui/icons-material';
function Schedule(props) {
  var token = (window.localStorage.getItem("acessToken"));
  const user = JSON.parse(window.localStorage.getItem("Name"));

  // declare all variables in order to send to js file
  var [shift, setShift] = useState("Morning_Operators");
  var [Date1, setDate1] = useState(new Date());
  const [Date2, setDate2] = useState(Date1.toISOString().slice(0, 10));
  const [Morning_operators, setMorning_operators] = useState([]);
  const [Machine_list, setMachine_list] = useState([]);
  const [part_dataset, setpart_dataset] = useState([]);
  const [Area, setArea] = useState("");
  const [isLoading, setIsLoading] = useState(false);


  const [data, setData] = useState([
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' },
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' },
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' },
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' },
    { Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Produced: '', Scrap: '', Supervisor_notes: '', Operator_2: '' }
  ]);

  const queryParams = {
    shift: shift,
    token: token,
    Date: Date2,
    user: user,
    Department: Area
  };
  // Hook to update the date as today's date
  useEffect(() => {
    const timer = setInterval(() => {
      setDate1(new Date(), 10);
    }, 1000); // Update every second

    return () => clearInterval(timer); // Cleanup on component unmount
  }, []);

  useEffect(() => {
    if (shift.length > 1) {
      Axios.get(`https://` + host + `/api/employees`, { params: queryParams })
        .then((data) => {
          const filteredEmployees = data.data.filter((employee) => employee.Name && employee.Name.length > 1);
          setMorning_operators(filteredEmployees);
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        });
    }
  }, [shift]);

  // to retreive Machines
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/Machines/${token}`).then((data) => {
      setMachine_list(data.data)
    });
  }, [])
  // to retreive Machines
  useEffect(() => {
    Axios.get(`https://` + host + `/realapi/get/part_dataset/${token}`).then((data) => {
      setpart_dataset(data.data)

    });
  }, [])

  // select rigid/flex or all parts
  const area = () => {

    if (Area == "Rigid") {
      console.log("rigid called");
      Axios.get(`https://` + host + `/realapi/get/Rigidpart_dataset/${token}`).then((data) => {
        setpart_dataset(data.data)
      });
      Axios.get(`https://` + host + `/realapi/get/RigidMachines/${token}`).then((data) => {
        setMachine_list(data.data)
      });
    }
    if (Area == "Flex") {
      Axios.get(`https://` + host + `/realapi/get/Flexpart_dataset/${token}`).then((data) => {
        setpart_dataset(data.data)
      });
      Axios.get(`https://` + host + `/realapi/get/FlexMachines/${token}`).then((data) => {
        setMachine_list(data.data)
      });
    }
    if (Area == "Others") {
      Axios.get(`https://` + host + `/realapi/get/part_dataset/${token}`).then((data) => {
        setpart_dataset(data.data)
      });
      Axios.get(`https://` + host + `/realapi/get/Machines/${token}`).then((data) => {
        setMachine_list(data.data)
      });
    }
  }
  useEffect(() => {
    const newData = data.map((line) => ({ ...line, Date: Date2 }));
    setData(newData);
  }, [Date2])

  const submitValue = (e, index) => {
    const newData = [...data];
    newData[index].Part_internalNo = e.target.value;
    newData[index].Customer = (part_dataset.find((x) => x.Part_internalNo === e.target.value).Customer);
    newData[index].PartNo = part_dataset.find((x) => x.Part_internalNo === e.target.value).PartNo;
    newData[index].Part_description = part_dataset.find((x) => x.Part_internalNo === e.target.value).Part_description;
    setData(newData);
  }

  const addLines = () => {
    if (data.length < 15) {
      const newData = [...data];
      for (let i = 0; i < 5; i++) {
        newData.push({ Date: Date2, Operator: '', Part_internalNo: '', PartNo: '', Customer: '', Part_description: '', Target: '', work_station: '', Supervisor_notes: '', Operator_2: '' });
      }
      setData(newData);
    } else {
      window.alert("Lines Reached maximum limit")
    }

  }
  const addNewRow = (index, operatorName, work_station) => {
    const newData = [...data];
    const newRow = { Operator: operatorName, Part_internalNo: '', Customer: '', PartNo: '', Part_description: '', Target: '', work_station: work_station, Operator_2: '', Supervisor_notes: '' };
    newData.splice(index + 1, 0, newRow);
    setData(newData);
  };
  useEffect(() => {
    const initialData = Morning_operators.map((operator, idx) => ({
      id: idx + 1, // Add a unique key for each initial row
      Operator: operator.Name,
      Part_internalNo: '',
      Customer: '',
      PartNo: '',
      Part_description: '',
      Target: '',
      work_station: '',
      Operator_2: '',
      Supervisor_notes: ''
    }));
    setData(initialData);
  }, [Morning_operators]);

  const handleLogout = () => {
    window.localStorage.removeItem("acessToken");
    window.localStorage.removeItem("Name");
    sessionStorage.removeItem('token');
    sessionStorage.removeItem('user');
    props.history.push('/');
  }
  const userMenu = (
    <Menu>
      <Menu.Item key="logout">
        <a onClick={handleLogout} href="/">
          <LogoutOutlined />
          Logout
        </a>
      </Menu.Item>
    </Menu>
  );

  const submitdata = () => {
    const filteredData = [];

    data.forEach(item => {
      if (
        item.Operator &&
        item.Part_internalNo &&
        item.PartNo &&
        item.Customer &&
        item.Part_description
      ) {
        const newData = [
          Date2,
          item.Operator,
          item.Part_internalNo,
          item.PartNo,
          item.Customer,
          item.Part_description,
          item.Target,
          item.work_station,
          item.Supervisor_notes,
          item.Operator_2,
          user,
          getFormattedDateTime(Date1),
          shift

        ];
        filteredData.push(newData);

      }

    });
    console.log(filteredData);

    setIsLoading(true);
    setTimeout(() => {
      Axios.post(`https://` + host + `/realapi/Schedule_entry`, { filteredData, token }).then((response) => {
        if (response.status === 200) {
          window.alert(response.data.message);
          setIsLoading(false);
          window.location.reload();

        }
      })
        .catch(error => {
          window.alert("Error Occured")
          setIsLoading(false);
        });
    }, 2000)

  }

  const getFormattedDateTime = (date) => {
    return format(date, 'yyyy-MM-dd HH:mm:ss');
  };

  return (
    <div>
      <br></br>
      <h1>Create New Schedule</h1>
      <br></br>
      <div class="row">
        <div class="col-2" >
          <label><b>
            <h4> Select Shift</h4>
          </b></label>
          <div class="row">
            <select
              name="shift"
              className="form-control form-rounded"
              placeholder="Select Shift"
              value={shift}
              onChange={(e) => { setShift(e.target.value) }}
            > <option value="">--Select Shift--</option>
              <option value="Morning_Operators">Morning Shift</option>
              <option value="Night_Operators">Night Shift</option>
              <option value="Woodshop">Woodshop/Upholstery</option>
            </select>
          </div>
        </div>

        <div class="col-2">
          <label><b>
            <h4> Select Department</h4>
          </b></label>
          <select type="select" class="form-control form-rounded" id='Area' placeholder="Area" onChange={(e) => {
            setArea(e.target.value)
          }} onClick={area} onKeyDown={area} name="area"
          > <option value="Others">Others</option>
            <option value="Flex">Flex</option>
            <option value="Rigid">Rigid</option>
          </select>
        </div>

        <div class="col-2">
          <div class="row">
            <div class="col">
              <label><b>
                <h4>Date</h4>
              </b></label>
              <input type="date" class="form-control form-rounded" placeholder="Date" name="Date" id='date' defaultValue={Date1.toISOString().slice(0, 10)} onChange={(e) => {
                setDate2(e.target.value)
              }} required />
            </div>
          </div>
        </div>
        <div class="col-4"></div>


        <div class="col-2">

          <div className="top-bar">
            {/* Render the logo */}
            {/* Render the user dropdown */}
            <div className="user-dropdown">
              <Dropdown overlay={userMenu} trigger={['click']}>

                <Button icon={<UserOutlined />} size="large">
                  Welcome {user.replace(/[{""}]/g, "")}!
                </Button>
              </Dropdown>
            </div>
          </div>
        </div>
      </div>
      {/*   {data.map((line, index) => (<div>
        <div key={index} className="row">
          <div className="col">
            <label>
              <h4></h4>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                if (index === 0) {
                  for (let i = 0; i < newData.length; i++) {
                    newData[i].Operator = e.target.value;
                  }
                }
                else {
                  newData[index].Operator = e.target.value;
                }
                setData(newData);
              }}
              list="operatorList"
              id="operators"
              value={data[index].Operator}
              placeholder="Enter Operator"
            />
            <datalist id="operatorList">
              <option></option>
              {Morning_operators.map(x => {
                return <option>{x.Name}</option>
              })}
            </datalist>
          </div>
          <div className="col-2">
            <br />
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Part_internalNo = e.target.value;
                setData(newData);
              }}
              onBlur={(e) => submitValue(e, index)} // Call the submitValue function on blur
              list="InternalPartNo"
              id="operator"
              placeholder="Enter Internal Part No"
            />
            <div className="scrollbar">
              <datalist id="InternalPartNo">
                <option></option>
                {part_dataset.map((x) => {
                  return <option>{x.Part_internalNo}</option>;
                })}
              </datalist>
            </div>
          </div>

          <div className="col" id='Hide'>
            <br />
            <input
              type="text"
              className="form-control form-rounded"
              value={data[index].Customer}
              placeholder="Enter Customer"
              readOnly
              required

            />
          </div>
          <div className="col" id='Hide'>
            <br />
            <input
              type="text"
              className="form-control form-rounded"
              value={data[index].PartNo}
              placeholder="Enter Part No"
              readOnly

            />
          </div>
          <div className="col" id='Hide'>
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              value={data[index].Part_description}
              readOnly
              placeholder="Enter Part Description"

            />
          </div>
          <div className="col-1">
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Target = e.target.value;
                newData[index].Produced = e.target.value;
                setData(newData);
              }}
              placeholder="Target"
              name="target"
              required
            />
          </div>
          <div className="col">
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                if (index === 0) {
                  for (let i = 0; i < newData.length; i++) {
                    newData[i].work_station = e.target.value;
                  }
                }
                else {
                  newData[index].work_station = e.target.value;
                }
                setData(newData);
              }}
              placeholder="Work Station"
              value={data[index].work_station}
              list="Work_station"
            />
            <datalist id="Work_station">
              <option></option>
              {Machine_list.map(x => {
                return <option>{x.Machines}</option>
              })}

            </datalist>
          </div>
          <div className="col-1">
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Operator_2 = e.target.value;
                setData(newData);
              }}
              placeholder="Operator-2 (Optional)"
              name="operator_2"
              list="operatorList"
              required
            />
          </div>
          <div className="col-2">
            <label>
              <b>
                <h3>Supervisor Notes</h3>
              </b>
            </label>
            <textarea
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Supervisor_notes = e.target.value;
                setData(newData);
              }}
              placeholder="Supervisor Notes"
              name="Supervisor_notes"
            />
          </div>
        </div>
      </div>
      ))}   */}
      {data.map((line, index) => (
        <div key={line.id} className="row">
          <div className="col">
            <label>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Operator = e.target.value;
                setData(newData);
              }}
              list="operatorList"
              id="operators"
              value={line.Operator}
              placeholder="Enter Operator"
            />
            <datalist id="operatorList">
              <option key="empty-option"></option>
              {Morning_operators.map((x) => (
                <option key={x.Name}>{x.Name}</option>
              ))}
            </datalist>
          </div>
          <div className="col-2">
            <br />
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Part_internalNo = e.target.value;
                setData(newData);
              }}
              onBlur={(e) => submitValue(e, index)} // Call the submitValue function on blur
              list="InternalPartNo"
              id="operator"
              placeholder="Enter Internal Part No"
            />
            <div className="scrollbar">
              <datalist id="InternalPartNo">
                <option key="empty-option"></option>
                {part_dataset.map((x, idx) => (
                  <option key={x.Part_internalNo + idx}>{x.Part_internalNo}</option>
                ))}
              </datalist>
            </div>
          </div>
          <div className="col-1">
            <label>
              <b>
                <h3></h3>
              </b>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Target = e.target.value;
                setData(newData);
              }}
              placeholder="Target"
              name="target"
              required
            />
          </div>
          <div className="col">
            <label>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].work_station = e.target.value;
                setData(newData);
              }}
              placeholder="Work Station"
              value={line.work_station}
              list="Work_station"
            />
            <datalist id="Work_station">
              <option key="empty-option"></option>
              {Machine_list.map((x, idx) => (
                <option key={x.Machines + idx}>{x.Machines}</option>
              ))}
            </datalist>
          </div>
          <div className="col-1">
            <label>
            </label>
            <input
              type="text"
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Operator_2 = e.target.value;
                setData(newData);
              }}
              placeholder="Operator-2 (Optional)"
              name="operator_2"
              list="operatorList"
            />
          </div>
          <div className="col-2">
            <label>
            </label>
            <textarea
              className="form-control form-rounded"
              onChange={(e) => {
                const newData = [...data];
                newData[index].Supervisor_notes = e.target.value;
                setData(newData);
              }}
              placeholder="Supervisor Notes"
              name="Supervisor_notes"
            />
          </div>
          <div className="col-sm-1">
            <button key={line.Operator} onClick={() => addNewRow(index, line.Operator, line.work_station)} className="form-control form-rounded">
              Add {(line.Operator).split(0, 10)}
            </button>
          </div>
        </div>
      ))}

      <button onClick={submitdata} class="btn btn-primary">Submit</button>
      <button onClick={addLines} class="btn btn-success">Add Row</button>
      <Link to="/RetrieveData">
        <button type="button" class="btn btn-success" >Read Table </button>
      </Link><Link to="/Scheduling">
        <button type="button" class="btn btn-secondary" >Scheduling </button>
      </Link>
      {isLoading ?
        <div className='overlay'>
          <div class="text-center">
            <div class="spinner-border" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </div> : null}

    </div>
  )
}

export default Schedule